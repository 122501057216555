import { observer } from '@legendapp/state/react'
import { MgButton, Paragraph, YStack } from '@my/ui'
import { useTranslation } from 'react-i18next'

import { IconGenerateMore } from './IconGenerateMore'
import { useGenerateMore } from './hooks/useGenerateMore'

export const GenerateMoreSlide = observer(() => {
  const [t] = useTranslation('postDetail', { keyPrefix: 'generateMore' })
  const { onGenerateMore } = useGenerateMore()
  return (
    <YStack w="100%" flex={1} ai="center" jc="center" mt="$-12">
      <IconGenerateMore size="$12" color="$gray9" />
      <MgButton.Inverse mt="$6" onPress={onGenerateMore}>
        <MgButton.Text>
          {t('btnText')}
          {' >>'}
        </MgButton.Text>
      </MgButton.Inverse>
      <Paragraph theme="alt1" mt="$3">
        {t('hint')}
      </Paragraph>
    </YStack>
  )
})
