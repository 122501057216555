import { useComputed } from '@legendapp/state/react'
import { useUser } from 'app/utils/useUser'

export const useUserCredits = () => {
  const { userData$ } = useUser()
  const creditsUsed$ = useComputed(() => {
    const credits = userData$.credits.get()
    if (!credits) return 0
    return +Number(
      Number(credits.addon_credits_used) + Number(credits.subscription_credits_used)
    ).toFixed(2)
  })
  const creditsTotal$ = useComputed(() => {
    const credits = userData$.credits.get()
    if (!credits) return 0
    return Number(credits.addon_credits) + Number(credits.subscription_credits)
  })
  const creditsLeft$ = useComputed(() => {
    return +Number(creditsTotal$.get() - creditsUsed$.get()).toFixed(2)
  })

  return {
    creditsUsed$,
    creditsTotal$,
    creditsLeft$,
  }
}
