import {
  Dialog,
  DialogContentProps,
  DialogOverlayProps,
  DialogPortalProps,
  DialogProps,
  withStaticProperties,
} from 'tamagui'

export type MgDialogProps = DialogProps

const Wrapper = (props: MgDialogProps) => {
  return <Dialog {...props}>{props.children}</Dialog>
}

const PortalWrapper = ({
  overlayProps,
  ...props
}: DialogPortalProps & { overlayProps?: DialogOverlayProps }) => {
  return (
    <Dialog.Portal {...props}>
      <Dialog.Overlay
        key="overlay"
        animation="quick"
        opacity={0.3}
        enterStyle={{ opacity: 0 }}
        exitStyle={{ opacity: 0 }}
        {...overlayProps}
      />
      {props.children}
    </Dialog.Portal>
  )
}

const PurePortalWrapper = (props: DialogPortalProps) => {
  return <Dialog.Portal {...props}>{props.children}</Dialog.Portal>
}

const OverlayWrapper = (props: DialogOverlayProps) => {
  return (
    <Dialog.Overlay
      key="overlay"
      animation="quick"
      opacity={0.3}
      enterStyle={{ opacity: 0 }}
      exitStyle={{ opacity: 0 }}
      {...props}
    />
  )
}

const ContentWrapper = (props: DialogContentProps) => {
  return (
    <Dialog.Content
      bordered
      elevate
      key="content"
      animateOnly={['transform', 'opacity']}
      animation={[
        'quick',
        {
          opacity: {
            overshootClamping: true,
          },
        },
      ]}
      enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
      exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
      backgroundColor="rgba(10, 10, 10, 0.99)"
      borderWidth="$1"
      {...props}
    >
      {props.children}
    </Dialog.Content>
  )
}

export const MgDialog = withStaticProperties(Wrapper, {
  Portal: PortalWrapper,
  PurePortal: PurePortalWrapper,
  Overlay: OverlayWrapper,
  Content: ContentWrapper,
  Trigger: Dialog.Trigger,
  Close: Dialog.Close,
})
