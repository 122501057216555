import { observer, useObservable } from '@legendapp/state/react'
import { ChevronLeft, ChevronRight } from '@tamagui/lucide-icons'
import { useRef, useState } from 'react'
import { Animated, StyleProp, ViewStyle } from 'react-native'
import { ScrollViewProps, XStack, YStack, styled, withStaticProperties, ScrollView } from 'tamagui'

export type MgScrollViewIndicatorProps = ScrollViewProps & {
  scrollbarStyle?: StyleProp<ViewStyle>
  showNavigator?: boolean
}

export const useIndicator = () => {
  const [completeScrollBarVal, setCompleteScrollBarVal] = useState(0)
  const [visibleScrollBarVal, setVisibleScrollBarVal] = useState(0)
  const scrollIndicator = useRef(new Animated.Value(0)).current
  const scrollIndicatorSize =
    completeScrollBarVal > visibleScrollBarVal
      ? (visibleScrollBarVal * visibleScrollBarVal) / completeScrollBarVal
      : visibleScrollBarVal
  const difference =
    visibleScrollBarVal > scrollIndicatorSize ? visibleScrollBarVal - scrollIndicatorSize : 1
  const scrollIndicatorPosition = Animated.multiply(
    scrollIndicator,
    visibleScrollBarVal / completeScrollBarVal
  ).interpolate({
    extrapolate: 'clamp',
    inputRange: [0, difference],
    outputRange: [0, difference],
  })
  return {
    completeScrollBarVal,
    setCompleteScrollBarVal,
    visibleScrollBarVal,
    setVisibleScrollBarVal,
    scrollIndicatorPosition,
    scrollIndicator,
    scrollIndicatorSize,
  }
}

const Vertical = observer(({ scrollbarStyle, ...props }: MgScrollViewIndicatorProps) => {
  const {
    completeScrollBarVal,
    setCompleteScrollBarVal,
    visibleScrollBarVal,
    setVisibleScrollBarVal,
    scrollIndicatorPosition,
    scrollIndicator,
    scrollIndicatorSize,
  } = useIndicator()
  const onContentSizeChange = (_, height) => setCompleteScrollBarVal(height)
  const onLayout = ({
    nativeEvent: {
      layout: { height },
    },
  }) => {
    setVisibleScrollBarVal(height)
  }

  return (
    <XStack h="100%" gap="$1.5" ov="hidden">
      <ScrollView
        f={1}
        h="100%"
        centerContent
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        onContentSizeChange={onContentSizeChange}
        onLayout={onLayout}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollIndicator } } }], {
          useNativeDriver: false,
        })}
        {...props}
      />
      {completeScrollBarVal > visibleScrollBarVal && (
        <XStack w={6} h="100%" bg="$gray3" mt="$1.5">
          <Animated.View
            style={[
              {
                backgroundColor: 'hsl(209, 100%, 60.6%)',
                borderRadius: 6,
                height: scrollIndicatorSize,
                transform: [{ translateY: scrollIndicatorPosition }],
                width: 3,
              },
              scrollbarStyle,
            ]}
          />
        </XStack>
      )}
    </XStack>
  )
})

const Horizontal = observer(
  ({ scrollbarStyle, showNavigator, ...props }: MgScrollViewIndicatorProps) => {
    const {
      completeScrollBarVal,
      setCompleteScrollBarVal,
      visibleScrollBarVal,
      setVisibleScrollBarVal,
      scrollIndicatorPosition,
      scrollIndicator,
      scrollIndicatorSize,
    } = useIndicator()
    const ref = useRef<any>()

    const onContentSizeChange = (width) => setCompleteScrollBarVal(width)
    const xOffset$ = useObservable(0)
    scrollIndicator.addListener(({ value }) => {
      xOffset$.set(value)
    })
    const canScroll = completeScrollBarVal > visibleScrollBarVal
    const canScrollLeft = xOffset$.get() > 0
    const canScrollRight = xOffset$.get() < completeScrollBarVal - visibleScrollBarVal
    const onLayout = ({
      nativeEvent: {
        layout: { width },
      },
    }) => {
      setVisibleScrollBarVal(width)
    }

    return (
      <XStack w="100%" gap="$3" ai="center">
        {showNavigator && canScroll && (
          <Operator
            opacity={canScrollLeft ? 1 : 0.5}
            disabled={!canScrollLeft}
            onPress={() =>
              ref.current?.scrollTo({
                x: xOffset$.get() - completeScrollBarVal * 0.3,
                animated: true,
              })
            }
          >
            <ChevronLeft size="$1" strokeWidth={2.5} />
          </Operator>
        )}
        <YStack f={1} gap="$1.5">
          <ScrollView
            ref={ref}
            fg={0}
            fs={0}
            w="100%"
            horizontal
            centerContent
            showsHorizontalScrollIndicator={false}
            scrollEventThrottle={16}
            onContentSizeChange={onContentSizeChange}
            onLayout={onLayout}
            onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: scrollIndicator } } }], {
              useNativeDriver: false,
            })}
            {...props}
          />
          {completeScrollBarVal > visibleScrollBarVal && (
            <XStack w="100%" bg="$gray3" height={4} mt="$1.5">
              <Animated.View
                style={[
                  {
                    backgroundColor: 'hsl(209, 100%, 60.6%)',
                    borderRadius: 6,
                    width: scrollIndicatorSize,
                    transform: [{ translateX: scrollIndicatorPosition }],
                    height: 3,
                  },
                  scrollbarStyle,
                ]}
              />
            </XStack>
          )}
        </YStack>
        {showNavigator && canScroll && (
          <Operator
            opacity={canScrollRight ? 1 : 0.5}
            disabled={!canScrollRight}
            onPress={() =>
              ref.current?.scrollTo({
                x: xOffset$.get() + completeScrollBarVal * 0.3,
                animated: true,
              })
            }
          >
            <ChevronRight size="$1" strokeWidth={2.5} />
          </Operator>
        )}
      </XStack>
    )
  }
)

const Operator = styled(XStack, {
  width: '$3',
  backgroundColor: '$gray5',
  height: '$3',
  borderRadius: '$10',
  ai: 'center',
  jc: 'center',
  cur: 'pointer',
  pressStyle: { backgroundColor: '$gray6' },
  $sm: { width: '$2', h: '$2' },
})

export const MgScrollViewIndicator = withStaticProperties(() => null, {
  Vertical,
  Horizontal,
})
