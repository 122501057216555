import { Observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { Dialog, MgButton, MgDialog, SizableText, XStack, YStack } from '@my/ui'
import { AlertCircle } from '@tamagui/lucide-icons'
import { useIsPrivatePost } from 'app/features/posts/hooks/usePostStatus'
import { privatePostIds$, publicPostIds$ } from 'app/features/posts/stores/operatedPosts'
import { api } from 'app/utils/api'
import { useTranslation } from 'react-i18next'

import { store$ } from '../../../context/PostDetailModelContext'

export const ChangeScopeDialog = observer(({ open$ }: { open$: Observable<boolean> }) => {
  const [t] = useTranslation('postDetail', { keyPrefix: 'scopeDialog' })
  const postId = Number(store$.post.id.get())
  const isPrivatePost = useIsPrivatePost(store$.post.get())

  const updateMutation = api.post.update.useMutation()

  const onUpdate = () => {
    updateMutation.mutateAsync({ id: postId, private: !isPrivatePost })
    if (isPrivatePost) {
      publicPostIds$.add(postId)
      privatePostIds$.delete(postId)
    } else {
      publicPostIds$.delete(postId)
      privatePostIds$.add(postId)
    }
    open$.set(false)
  }

  return (
    <MgDialog open={open$.get()} onOpenChange={(v) => open$.set(v)}>
      <MgDialog.Portal overlayProps={{ o: 0.3 }}>
        <MgDialog.Content maxWidth={360} bw="$1" boc="$gray2">
          <YStack ai="center" gap="$2">
            <AlertCircle strokeWidth={2.5} color="$blue9" size="$3" />
            <Dialog.Title size="$7" textTransform="capitalize">
              {isPrivatePost ? t('public') : t('private')}
            </Dialog.Title>
            <SizableText ta="center">
              {isPrivatePost ? t('publicDesc') : t('privateDesc')}
            </SizableText>
            <XStack mt="$3" gap="$3">
              <MgButton.Active onPress={onUpdate}>
                <MgButton.Text>{t('confirm')}</MgButton.Text>
              </MgButton.Active>
              <MgButton.Default onPress={() => open$.set(false)}>
                <MgButton.Text>{t('cancel')}</MgButton.Text>
              </MgButton.Default>
            </XStack>
          </YStack>
        </MgDialog.Content>
      </MgDialog.Portal>
    </MgDialog>
  )
})
