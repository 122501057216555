import { pathString } from 'app/utils/url'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'

export const LanguageOptions: { label: string; value: languageType }[] = [
  { label: '简体中文', value: 'zh' },
  { label: 'English', value: 'en' },
]
type languageType = 'zh' | 'en'

export const useLanguage = () => {
  const router = useRouter()
  const switchLanguage = () => {
    const locale = router.locale === 'zh' ? 'en' : 'zh'
    Cookies.set('NEXT_LOCALE', locale, { expires: 31536000000 })
    const newUrl =
      (locale === 'en' ? '' : '/zh') +
      pathString(location.pathname.replace(/(^\/zh)/, ''), {
        search: location.search,
        hash: location.hash,
      })
    location.replace(newUrl)
  }
  return {
    currentLanguage: router.locale as languageType,
    switchLanguage,
  }
}
