import { observer, useSelector } from '@legendapp/state/react'
import { MgLikeButton, Text } from '@my/ui'
import { ActionIcon } from 'app/components/ActionIcon'
import { IconExitRightCorner } from 'app/icons/IconExitRightCorner'
import { formatNumber2Str } from 'app/utils/number'

import {
  usePostDislike,
  usePostLike,
  usePostLikeCount,
  usePostLikeStatus,
} from '../../hooks/usePostInteractions'
import { store$ } from '../context/PostDetailModelContext'
import { showShareDialog } from './PostDetailMenu/components/ShareDialog'

export const PostInteractions = observer(() => {
  const post = useSelector(() => store$.post.get())
  const { setPostLike } = usePostLike()
  const { setPostDislike } = usePostDislike()
  const like$ = usePostLikeStatus(post)
  const likes$ = usePostLikeCount(post)

  if (!post) return null

  return (
    <div className="flex gap-4 py-4">
      <div className="flex w-full cursor-pointer items-center justify-end gap-2">
        <div onClick={() => (like$.get() ? setPostDislike(post) : setPostLike(post))}>
          <MgLikeButton size="$1" checked={like$.get()} />
        </div>
        <Text color="$gray11">{formatNumber2Str(likes$.get())}</Text>
      </div>
      <ActionIcon
        icon={<IconExitRightCorner className="hover:text-primary h-5 w-5 text-gray-700" />}
        square
        onClick={showShareDialog}
      />
    </div>
  )
})
