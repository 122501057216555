import { IModel } from 'app/features/models/typings'
import { IPost } from 'app/features/posts/typings/post'
import constate from 'constate'

export interface IProps {
  image: { pid: string; width: number; height: number }
  post: IPost
  model: IModel
  showAvatarBtn?: boolean
  showTemplateBtn?: boolean
  showDeleteBtn?: boolean
  onDelete?: () => void
}

const useGeneratedImgWithOPInner = (props: IProps) => {
  return {
    ...props,
  }
}

export const [GeneratedImgWithOPProvider, useGeneratedImgWithOP] = constate(
  useGeneratedImgWithOPInner
)
