import { observer, useComputed, useObservable } from '@legendapp/state/react'
import { MgPopover, Paragraph, ParagraphProps, Popover, XStack, XStackProps, YStack } from '@my/ui'
import { IconProps } from '@tamagui/helpers-icon'
import {
  ArrowDownToLine,
  Link as IconLink,
  Lock,
  Redo,
  Trash2,
  Unlock,
} from '@tamagui/lucide-icons'
import { ActionIcon } from 'app/components/ActionIcon'
import { useIsPrivatePost } from 'app/features/posts/hooks/usePostStatus'
import { IconDotsCicle } from 'app/icons/IconDotsCicle'
import { copyToClipboard } from 'app/utils/hooks/useClipboard'
import { useUser } from 'app/utils/useUser'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { store$ } from '../../context/PostDetailModelContext'
import { onDownload } from '../../event'
import { ChangeScopeDialog } from './components/ChangeScopeDialog'
import { DeletePostDialog } from './components/DeletePostDialog'
import { showShareDialog } from './components/ShareDialog'

export const PostDetailMenu = observer(() => {
  const [t] = useTranslation('postDetail', { keyPrefix: 'menu' })
  const { user } = useUser()
  const deleteDialog$ = useObservable(false)
  const scopeDialog$ = useObservable(false)
  const isSelfPost = store$.post.creator.get() === user?.id
  const isPrivatePost = useIsPrivatePost(store$.post.get())
  const isRemainFace$ = useComputed(() => {
    const images = store$.post.images.get()
    return images?.some(
      (image) => image.inpaint_info && !!image.inpaint_info.remain_face_human_ids.length
    )
  })
  return (
    <>
      <MgPopover>
        <MgPopover.Trigger>
          <ActionIcon
            icon={
              <IconDotsCicle
                strokeWidth={3}
                className="hover:text-primary-active h-6 w-6 text-gray-600"
              />
            }
            square
          />
        </MgPopover.Trigger>
        <MgPopover.Content shadowColor="$gray9" shadowRadius={16} p="$1.5">
          <YStack ai="center">
            {!isPrivatePost && <MenuItem text={t('share')} icon={Redo} onPress={showShareDialog} />}
            <MenuItem
              icon={ArrowDownToLine}
              text={t('download')}
              onPress={() => {
                onDownload.fire()
              }}
            />
            <MenuItem
              text={t('copy')}
              icon={IconLink}
              onPress={() => copyToClipboard(`${location.origin}/post/${store$.post.id.peek()}`)}
            />
            {isSelfPost && !isRemainFace$.get() && (
              <MenuItem
                text={isPrivatePost ? t('public') : t('private')}
                icon={isPrivatePost ? Unlock : Lock}
                onPress={() => scopeDialog$.set(true)}
              />
            )}
            {isSelfPost && (
              <MenuItem
                text={t('delete')}
                icon={Trash2}
                iconProps={{ color: '$red9' }}
                textProps={{ col: '$red9' }}
                containerProps={{ hoverStyle: { bg: '$red4' } }}
                onPress={() => deleteDialog$.set(true)}
              />
            )}
          </YStack>
        </MgPopover.Content>
      </MgPopover>
      <DeletePostDialog open$={deleteDialog$} />
      <ChangeScopeDialog open$={scopeDialog$} />
    </>
  )
})

const MenuItem = ({
  text,
  onPress,
  containerProps,
  iconProps,
  textProps,
  icon: Icon,
}: {
  text: string
  onPress: () => void
  containerProps?: XStackProps
  iconProps?: IconProps
  textProps?: ParagraphProps
  icon: React.FC<IconProps>
}) => {
  return (
    <Popover.Close asChild>
      <XStack
        w="100%"
        p="$2"
        gap="$2"
        br="$3"
        ai="center"
        cur="pointer"
        hoverStyle={{ bg: '$gray6' }}
        onPress={onPress}
        {...containerProps}
      >
        <Icon strokeWidth={3} size={16} {...iconProps} />
        <Paragraph fontSize={14} fontWeight="700" cursor="pointer" {...textProps}>
          {text}
        </Paragraph>
      </XStack>
    </Popover.Close>
  )
}
