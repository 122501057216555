import { Memo, useObserve, useSelector } from '@legendapp/state/react'
import { Input, MoggButton, XStack } from '@my/ui'
import { XCircle } from '@tamagui/lucide-icons'
import { useCheckLogin } from 'app/features/auth/hooks/useCheckLogin'
import { useEffect, useRef } from 'react'
import { TextInput } from 'react-native'
import { useAddComment } from './hooks/useAddComment'
import { editor$, resetEditStore } from './store/editor'

export const CommentEditor = ({ topic, onComment }: { topic: string; onComment?: () => void }) => {
  const ref = useRef<TextInput>(null)
  const edited = useSelector(() => !!editor$.value.get())
  const isReplying = useSelector(() => !!editor$.parentId.get() && !!editor$.replyingTo.get())
  const addComments = useAddComment()
  const { checkLogin } = useCheckLogin()

  useObserve(() => {
    if (editor$.focus.get()) {
      ref.current?.focus()
    }
  })

  useEffect(() => {
    resetEditStore()
    editor$.topic.set(topic)
  }, [topic])

  return (
    <XStack ai="center" gap="$3.5" width="100%">
      <XStack
        flex={1}
        backgroundColor="$background"
        borderRadius="$8"
        ai="center"
        gap="$2"
        jc="space-between"
        pr="$3.5"
        overflow="hidden"
        animation="200ms"
      >
        <Memo>
          {() => (
            <Input
              ref={ref}
              onChangeText={(v) => editor$.value.set(v)}
              flex={1}
              disabled={editor$.readOnly.get()}
              placeholder={editor$.placeholder.get()}
              value={editor$.value.get()}
              borderWidth="$0"
              size="$3"
              borderColor="$colorTransparent"
              focusStyle={{ outlineWidth: '$0' }}
              onBlur={() => editor$.focus.set(false)}
            />
          )}
        </Memo>

        {(edited || isReplying) && (
          <XStack cursor="pointer" onPress={() => resetEditStore({ topic: editor$.topic.peek() })}>
            <XCircle color="$gray9" size={18} />
          </XStack>
        )}
      </XStack>
      <XStack
        width={edited ? 48 : 0}
        opacity={edited ? 1 : 0}
        animateOnly={['width', 'opacity']}
        animation="100ms"
        overflow="hidden"
      >
        <MoggButton
          size="$2"
          loading={addComments.isLoading}
          onPress={() => {
            if (checkLogin()) {
              addComments.mutate()
              onComment?.()
            }
          }}
        >
          Send
        </MoggButton>
      </XStack>
    </XStack>
  )
}
