import { observable } from '@legendapp/state'
import { observer, useObserve } from '@legendapp/state/react'
import {
  ButtonProps,
  Circle,
  Dialog,
  FullscreenSpinner,
  MgButton,
  MgDialog,
  Separator,
  SizableText,
  XStack,
  YStack,
  styled,
  withStaticProperties,
} from '@my/ui'
import { X } from '@tamagui/lucide-icons'
import { SocialShare } from 'app/features/socialMedia/SocialShare/SocialShare'
import { api } from 'app/utils/api'
import { COOKIE_INVITER_ID } from 'app/utils/constants/cookie'
import { copyToClipboard } from 'app/utils/hooks/useClipboard'
import { i18nInstance } from 'app/utils/i18n'
import { useUser } from 'app/utils/useUser'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import enMessages from '../locales/en/inviteDialog.json'
import zhMessages from '../locales/zh/inviteDialog.json'

i18nInstance.addResourceBundle('en', 'inviteDialog', enMessages)
i18nInstance.addResourceBundle('zh', 'inviteDialog', zhMessages)

const open$ = observable(false)

export const showInviteDialog = () => open$.set(true)

const shareTitle = 'MyDreamBoy - Pick Your AI Boyfriend'

export const InviteDialogInner = observer(() => {
  const { isLogin } = useUser()
  const [t] = useTranslation('inviteDialog')
  const router = useRouter()
  const referrerInfo = api.user.referrerInfo.useQuery(undefined, { enabled: isLogin })

  const link = `${location.origin}?${COOKIE_INVITER_ID}=${referrerInfo.data?.referrerId}`

  const onCopy = () => {
    copyToClipboard(link)
    toast('Link copied to clipboard', {
      position: 'top-center',
      duration: 2000,
      style: {
        background: 'hsl(206, 100%, 50.0%)',
        borderColor: 'hsl(206, 100%, 50.0%)',
        color: '#fff',
        padding: '8px 16px',
      },
    })
  }

  useObserve(() => {
    if (open$.get() && !isLogin) {
      router.push('/sign-in')
    }
  })

  return (
    <MgDialog open={open$.get() && isLogin} onOpenChange={(v) => open$.set(v)}>
      <MgDialog.Portal overlayProps={{ o: 0.8 }}>
        <MgDialog.Content w={480} $sm={{ w: '90%' }} bw="$0.5">
          {referrerInfo.isLoading ? (
            <YStack h={480}>
              <FullscreenSpinner theme="light" color="$color" />
            </YStack>
          ) : (
            <YStack>
              <XStack jc="space-between" ai="center">
                <Dialog.Title size="$5" tt="capitalize">
                  {t('title')}
                </Dialog.Title>
                <MgDialog.Close>
                  <Circle bg="$gray6" p="$2" cur="pointer" hoverStyle={{ bg: '$gray7' }}>
                    <X strokeWidth={2.5} size={16} />
                  </Circle>
                </MgDialog.Close>
              </XStack>
              <Separator my="$3" />
              <BlockContainer>
                <SubTitle>{t('spread')}</SubTitle>
                <DescText>{t('spreadDesc')}</DescText>
                <XStack gap="$3">
                  <XStack px="$2" br="$3" f={1} bg="$gray6" ai="center">
                    <SizableText>{link}</SizableText>
                  </XStack>
                  <MgButton.Active size="$2" onPress={onCopy}>
                    <MgButton.Text>{t('copy')}</MgButton.Text>
                  </MgButton.Active>
                </XStack>
              </BlockContainer>
              <BlockContainer mt="$5">
                <SubTitle>{t('reward')}</SubTitle>
                <DescText>{t('rewardDesc')}</DescText>
                <XStack gap="$5" jc="space-between" my="$4" $sm={{ fd: 'column', gap: '$3' }}>
                  <BoxContainer>
                    <SubTitle>{referrerInfo.data?.referredCount}</SubTitle>
                    <DescText size="$1">{t('signed')}</DescText>
                  </BoxContainer>
                  <BoxContainer>
                    <SubTitle>{(referrerInfo.data?.referredCount ?? 0) * 25}</SubTitle>
                    <DescText size="$1">{t('gotten')}</DescText>
                  </BoxContainer>
                </XStack>
              </BlockContainer>
              <Separator my="$5" $sm={{ my: '$3' }} />
              <BlockContainer>
                <SubTitle>{t('share')}</SubTitle>
                <SocialShare url={link} title={shareTitle} />
              </BlockContainer>
            </YStack>
          )}
        </MgDialog.Content>
      </MgDialog.Portal>
    </MgDialog>
  )
})

const InviteButton = observer((props: ButtonProps) => {
  const [t] = useTranslation('inviteDialog')

  return (
    <MgButton.Default size="$2" onPress={() => open$.set(true)} {...props}>
      <MgButton.Text>{t('title')}</MgButton.Text>
    </MgButton.Default>
  )
})

export const InviteDialog = withStaticProperties(InviteDialogInner, {
  TriggerBtn: InviteButton,
})

const BlockContainer = styled(YStack, {
  gap: '$2',
})

const SubTitle = styled(SizableText, {
  size: '$3',
  fow: '700',
})

const DescText = styled(SizableText, {
  size: '$2',
})

const BoxContainer = styled(YStack, {
  fg: 1,
  w: '30%',
  ai: 'center',
  py: '$5',
  bw: '$0.5',
  br: '$8',
  boc: '$gray8',
  $sm: { w: '100%' },
})
