import { observer, useObservable, useObserve } from '@legendapp/state/react'
import { ChevronLeft, ChevronRight } from '@tamagui/lucide-icons'
import { useEffect, useRef } from 'react'
import { Animated, FlatListProps, StyleProp, ViewStyle } from 'react-native'
import { XStack, YStack, YStackProps, styled, withStaticProperties } from 'tamagui'

import { useIndicator } from './MgScrollViewIndicator'

export type MgFlatListIndicatorProps = FlatListProps<any> & {
  index: number
  showNavigator?: boolean
  showIndicator?: boolean
  scrollStep?: number
  scrollbarStyle?: StyleProp<ViewStyle>
  containerProps?: YStackProps
}

const Horizontal = observer(
  ({
    index,
    showNavigator,
    showIndicator,
    scrollStep = 0.3,
    scrollbarStyle,
    containerProps,
    ...props
  }: MgFlatListIndicatorProps) => {
    const ref = useRef<Animated.FlatList>(null)
    const width$ = useObservable(0)
    const xOffset$ = useObservable(0)

    const {
      completeScrollBarVal,
      setCompleteScrollBarVal,
      visibleScrollBarVal,
      setVisibleScrollBarVal,
      scrollIndicatorPosition,
      scrollIndicator,
      scrollIndicatorSize,
    } = useIndicator()
    const canScroll = completeScrollBarVal > visibleScrollBarVal
    const canScrollLeft = xOffset$.get() > 0
    const canScrollRight = xOffset$.get() < completeScrollBarVal - visibleScrollBarVal

    useObserve(() => {
      scrollIndicator.setValue(xOffset$.get())
    })

    useEffect(() => {
      ref.current?.scrollToIndex({
        index,
        animated: true,
        viewPosition: 0.5,
      })
    }, [index])

    return (
      <XStack w="100%" ai="center" gap="$3" {...containerProps}>
        {showNavigator && canScroll && (
          <Operator
            opacity={canScrollLeft ? 1 : 0.5}
            disabled={!canScrollLeft}
            onPress={() =>
              ref.current?.scrollToOffset({
                offset: xOffset$.get() - width$.get() * scrollStep,
                animated: true,
              })
            }
          >
            <ChevronLeft size="$1" strokeWidth={2.5} />
          </Operator>
        )}
        <YStack f={1} gap="$1.5" w="100%">
          <Animated.FlatList
            style={{ width: '100%' }}
            ref={ref}
            horizontal
            showsHorizontalScrollIndicator={false}
            scrollEventThrottle={16}
            onLayout={({
              nativeEvent: {
                layout: { width },
              },
            }) => {
              setVisibleScrollBarVal(width)
            }}
            onContentSizeChange={(width) => {
              setCompleteScrollBarVal(width)
              width$.set(width)
            }}
            onScrollToIndexFailed={({ index }) => {
              const wait = new Promise((resolve) => setTimeout(resolve, 1000))
              wait.then(() => {
                ref.current?.scrollToIndex({ index })
              })
            }}
            onScroll={({ nativeEvent }) => {
              const xOffset = nativeEvent.contentOffset.x
              const width = nativeEvent.contentSize.width
              xOffset$.set(xOffset)
              width$.set(width)
            }}
            {...props}
          />
          {completeScrollBarVal > visibleScrollBarVal && showIndicator && (
            <XStack w="100%" bg="$gray3" height={4} mt="$1.5" pos="absolute" bottom="$-2">
              <Animated.View
                style={[
                  {
                    backgroundColor: 'hsl(209, 100%, 60.6%)',
                    borderRadius: 6,
                    width: scrollIndicatorSize,
                    transform: [{ translateX: scrollIndicatorPosition }],
                    height: 3,
                  },
                  scrollbarStyle,
                ]}
              />
            </XStack>
          )}
        </YStack>
        {showNavigator && canScroll && (
          <Operator
            opacity={canScrollRight ? 1 : 0.5}
            disabled={!canScrollRight}
            onPress={() =>
              ref.current?.scrollToOffset({
                offset: xOffset$.get() + width$.get() * scrollStep,
                animated: true,
              })
            }
          >
            <ChevronRight size="$1" strokeWidth={2.5} />
          </Operator>
        )}
      </XStack>
    )
  }
)

const Operator = styled(XStack, {
  width: '$3',
  backgroundColor: '$gray5',
  height: '$3',
  borderRadius: '$10',
  ai: 'center',
  jc: 'center',
  cur: 'pointer',
  pressStyle: { backgroundColor: '$gray6' },
  $sm: { width: '$2', h: '$2' },
})

export const MgFlatListIndicator = withStaticProperties(() => null, {
  Horizontal,
})
