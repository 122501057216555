import { observer, useObservable, useObserve } from '@legendapp/state/react'
import { useMemoizedFn } from 'ahooks'
import Modal from 'app/components/Modal'
import { getModelVersion, setNsfwModelVersion } from 'app/utils/model-version'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

export const NsfwDialog = observer(() => {
  const [t] = useTranslation('home', { keyPrefix: 'nsfw' })
  const open$ = useObservable(false)

  useObserve(() => {
    if (!getModelVersion()) {
      open$.set(true)
    } else {
      open$.set(false)
    }
  })

  const handleEnter = useMemoizedFn(() => {
    setNsfwModelVersion()
    open$.set(false)
  })

  return (
    <Modal
      isOpen={open$.get()}
      onClose={() => {}}
      showCloseButton={false}
      backLayerClassName="bg-black/80 backdrop-blur-xl"
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        transition={{ duration: 0.2 }}
        className="relative w-full max-w-md"
      >
        {/* Background glow effect */}
        <div className="absolute inset-0 -z-10 bg-gradient-to-b from-[#FFC24C]/10 to-transparent blur-[100px]" />

        <div className="overflow-hidden rounded-xl bg-[#1C1C1E]/90">
          {/* Top gradient line */}
          <div className="h-[1px] w-full bg-gradient-to-r from-transparent via-[#FFC24C]/50 to-transparent" />

          <div className="p-8 sm:p-10">
            <div className="flex flex-col items-center space-y-6">
              <div className="space-y-2 text-center">
                <h1 className="font-grotesque text-2xl font-bold tracking-tight text-white">
                  {t('title')}
                </h1>
                <div className="mx-auto h-[2px] w-12 bg-gradient-to-r from-transparent via-[#FFC24C] to-transparent" />
              </div>

              <p className="text-center text-lg text-gray-800">{t('hint1')}</p>

              <p className="text-center text-lg font-medium text-[#FFC24C]">{t('hint2')}</p>

              <p className="max-w-sm text-center text-sm leading-relaxed text-gray-700">
                {t('hint3')}{' '}
                <a href="/terms-of-service" className="text-[#FFC24C] hover:underline">
                  {t('serviceTerms')}
                </a>
                。
              </p>

              <div className="flex w-full flex-col gap-3 sm:flex-row">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleEnter}
                  className="font-grotesque group flex w-full items-center justify-center rounded-xl bg-gradient-to-r from-[#FFC24C] to-[#FFD700] px-6 py-3.5 text-xl font-bold text-black transition-all duration-200 hover:shadow-lg hover:shadow-[#FFC24C]/20"
                >
                  {t('confirm')}
                </motion.button>

                <motion.a
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  href="https://www.google.com"
                  className="font-grotesque flex w-full items-center justify-center rounded-xl border border-white/10 bg-white/5 px-6 py-3.5 text-xl font-bold text-white/90 backdrop-blur-sm transition-all duration-200 hover:border-white/20 hover:bg-white/10"
                >
                  {t('cancel')}
                </motion.a>
              </div>

              <div className="space-y-3 pt-4 text-center">
                <p className="text-xs leading-relaxed text-gray-500">{t('hint4')}</p>
                <div className="mx-auto h-[1px] w-16 bg-white/5" />
                <div className="space-y-2">
                  <p className="text-xs text-gray-600"> {t('hint5')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </Modal>
  )
})
