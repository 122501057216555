import { SVGProps, memo } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M12 2.433a9.568 9.568 0 1 1 0 19.135 9.568 9.568 0 0 1 0-19.135Zm0 8.186L9.299 7.907a1.023 1.023 0 0 0-1.392 0 1.023 1.023 0 0 0 0 1.392L10.649 12l-2.742 2.701a1.023 1.023 0 0 0 0 1.392 1.023 1.023 0 0 0 1.36 0L12 13.351l2.701 2.742a1.023 1.023 0 0 0 1.392 0 1.023 1.023 0 0 0 0-1.36L13.351 12l2.742-2.701a1.023 1.023 0 0 0 0-1.392 1.023 1.023 0 0 0-1.36 0L12 10.649M12 1a11 11 0 1 0 11 11A11.01 11.01 0 0 0 12 1Z"
    />
  </svg>
)
export const IconCrossCircle = memo(SvgComponent)
