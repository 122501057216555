import { observer } from '@legendapp/state/react'
import { Drawer, DrawerContent, DrawerPortal, DrawerTitle } from '@my/magic-ui'
import { useMemoizedFn } from 'ahooks'
import { useClientValue } from 'app/utils/hooks'
import { i18nInstance } from 'app/utils/i18n'
import { useRouter } from 'next/router'
import { useRef } from 'react'

import { PostDetailWrapper } from './components/PostDetailWrapper'
import { PostLeftBar } from './components/PostLeftBar'
import { hideModal, store$ } from './context/PostDetailModelContext'
import enMessages from '../locales/en/postDetail.json'
import zhMessages from '../locales/zh/postDetail.json'
i18nInstance.addResourceBundle('en', 'postDetail', enMessages)
i18nInstance.addResourceBundle('zh', 'postDetail', zhMessages)

export const PostDetailModal = observer(() => {
  const show = store$.show.get()
  const isClientSide = useClientValue(true)
  const touchStart = useRef(0)
  const isSwiping = useRef(false)
  const router = useRouter()

  const handleTouchStart = useMemoizedFn((e: React.TouchEvent) => {
    // 检查是否在 Swiper 区域
    const target = e.target as HTMLElement
    if (target.closest('.swiper') || target.closest('[role="presentation"]')) return

    touchStart.current = e.touches[0].clientX
    isSwiping.current = true
  })

  const handleTouchMove = useMemoizedFn((e: React.TouchEvent) => {
    if (!isSwiping.current) return

    const currentX = e.touches[0].clientX
    const diff = touchStart.current - currentX
    // 左滑超过 100px 时关闭
    if (diff < -100) {
      isSwiping.current = false
      hideModal()
    }
    if (diff > 100) {
      isSwiping.current = false
      router.push(`/model/${store$.model.id.get()}`)
    }
  })

  const handleTouchEnd = useMemoizedFn(() => {
    isSwiping.current = false
  })

  if (!isClientSide) return null

  return (
    <Drawer
      open={show}
      onClose={hideModal}
      shouldScaleBackground
      onOpenChange={(v) => {
        if (!v) hideModal()
      }}
    >
      <DrawerPortal>
        <DrawerContent
          className="z-[9999] bg-neutral-950 outline-none"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <DrawerTitle />
          <div className="mx-auto flex h-[100vh] h-dvh w-screen flex-col overflow-hidden md:h-[90vh] md:w-[90vw] md:flex-row md:px-5 md:pb-12">
            <PostLeftBar />
            <PostDetailWrapper />
          </div>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  )
})
