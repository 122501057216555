export async function downloadImage(
  imageSrc: string,
  nameOfDownload = `my-dreamboy.${imageSrc.split('.').pop()}`
) {
  const response = await fetch(imageSrc)

  const blobImage = await response.blob()

  const href = URL.createObjectURL(blobImage)

  const anchorElement = document.createElement('a')
  anchorElement.href = href
  anchorElement.download = nameOfDownload

  document.body.appendChild(anchorElement)
  anchorElement.click()

  document.body.removeChild(anchorElement)
  window.URL.revokeObjectURL(href)
}
