import { observable, observe } from '@legendapp/state'
import { IHumanParseAck } from 'app/features/tags/typings/user-img-ack'
import axios from 'axios'

const assetPrefix =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://alg.mydreamboy.com/storage/v1/object/public/user_imgs/'
    : 'https://alg.mydreamboy.app/storage/v1/object/public/user_imgs/'

export const imageEditorStore = {
  state$: observable({
    base64Img: '',
    parseResult: null as IHumanParseAck | null,
    remainHumanFaceIds: null as null | number[],
    sourceAssetPath: '',
  }),

  reset() {
    imageEditorStore.state$.set({
      base64Img: '',
      parseResult: null,
      remainHumanFaceIds: null,
      sourceAssetPath: '',
    })
  },

  resetHumanFaceIds() {
    imageEditorStore.state$.remainHumanFaceIds.set(null)
  },

  selectImg({
    base64,
    remainFaceIds,
    sourceAssetPath,
  }: {
    base64: string
    remainFaceIds: null | number[]
    sourceAssetPath: string
  }) {
    imageEditorStore.state$.base64Img.set(base64)
    imageEditorStore.state$.remainHumanFaceIds.set(remainFaceIds)
    imageEditorStore.state$.sourceAssetPath.set(sourceAssetPath)
  },
}

// check if human parse is same with source asset
observe(() => {
  const humanParseResult = imageEditorStore.state$.parseResult.get()
  const sourceAssetPath = imageEditorStore.state$.sourceAssetPath.get()
  if (humanParseResult && sourceAssetPath) {
    const humanParseResultAssetPath = `${assetPrefix}${sourceAssetPath}/0000_human_parse_result.json`

    // 使用 axios 获取解析结果
    axios
      .get(humanParseResultAssetPath)
      .then((response) => {
        const sourceParseResult =
          response.data as IHumanParseAck['human_parse_infos'][0]['human_parse_result']
        // 更新解析结果到 store
        if (
          Object.keys(sourceParseResult).length !==
          Object.keys(humanParseResult.human_parse_infos[0].human_parse_result ?? {}).length
        ) {
          imageEditorStore.state$.parseResult.set(null)
        }
      })
      .catch((error) => {
        console.error('get human parse result failed:', error)
      })
  }
})
