import { Switch, observer, useComputed } from '@legendapp/state/react'
import { i18nInstance } from 'app/utils/i18n'
import { useUser } from 'app/utils/useUser'
import { useTranslation } from 'react-i18next'

import enMessages from '../locales/en/userTierText.json'
import zhMessages from '../locales/zh/userTierText.json'

i18nInstance.addResourceBundle('en', 'userTierText', enMessages)
i18nInstance.addResourceBundle('zh', 'userTierText', zhMessages)

export const UserTierText = observer(() => {
  const [t] = useTranslation('userTierText')
  const { user$ } = useUser()

  const tier = useComputed(() => user$.data.usersCollection.edges[0]?.node.subscription_tier.get())

  return (
    <Switch value={tier}>
      {{
        free: () => <>{t('free')}</>,
        plus: () => <>{t('plus')}</>,
        premium: () => <>{t('premium')}</>,
        pro: () => <>{t('pro')}</>,
        default: () => <>-</>,
      }}
    </Switch>
  )
})
