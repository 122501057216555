import { Button, ButtonProps, Spinner } from 'tamagui'

export type MoggButtonProps = ButtonProps & {
  loading?: boolean
}

export const MoggButton: React.FC<MoggButtonProps> = ({ loading, ...props }: MoggButtonProps) => {
  return (
    <Button
      backgroundColor="$blue9"
      size="$3"
      br="$12"
      theme="blue"
      hoverStyle={{ bc: 'blue10' }}
      pressStyle={{ bc: '$blue9', b: 'none' }}
      opacity={props.disabled ? 0.5 : 1}
      {...props}
    >
      {loading ? <Spinner theme="light" color="$orange1" /> : props.children}
    </Button>
  )
}
