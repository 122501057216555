import TagsJson from '../config/tags.json'
import { ITagConfig, ITagInfo, ITagMapItemValue } from '../typings/tag'

export type TagsMap = {
  [key: number]: ITagMapItemValue
}

let defaultTagsMap: TagsMap | null
const groupNames = Object.keys(TagsJson)

export const getTagIdsMap = (
  parentInfo: ITagInfo | null,
  config: ITagConfig,
  map: TagsMap = {}
) => {
  const entries = Object.entries(config)
  for (const [prompt, config] of entries) {
    const selfInfo = config['info'] as ITagInfo
    parentInfo && map[parentInfo.tag_id]?.childsId.push(selfInfo.tag_id)
    map[selfInfo.tag_id] = {
      ...selfInfo,
      parentId: parentInfo?.tag_id ?? undefined,
      selected: [],
      prompt,
      childsId: [],
      max_num: selfInfo.max_num === -1 ? Infinity : selfInfo.max_num ?? 1,
      rootId:
        (!!parentInfo &&
          !groupNames.includes(map[parentInfo.tag_id]!.prompt) &&
          map[parentInfo.tag_id]!.rootId) ||
        selfInfo.tag_id!,
    }
    const childEntries = Object.entries(config).filter(([key]) => key !== 'info')
    if (childEntries.length) {
      const childConfig = Object.fromEntries(childEntries) as ITagConfig
      getTagIdsMap(selfInfo, childConfig, map)
    }
  }
  return map
}

export const getDefaultTagIdsMap = () => {
  if (!defaultTagsMap) {
    defaultTagsMap = getTagIdsMap(null, TagsJson)
  }
  return defaultTagsMap
}
