import { observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { MgAlertDialog, MgButton, Paragraph, XStack, YStack } from '@my/ui'
import { X } from '@tamagui/lucide-icons'
import { i18nInstance } from 'app/utils/i18n'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'solito/router'

import enMessages from '../locales/en/upgradeDialog.json'
import zhMessages from '../locales/zh/upgradeDialog.json'
import { CardDecorator } from './components/CardDecorator/CardDecorator'

i18nInstance.addResourceBundle('en', 'upgradeDialog', enMessages)
i18nInstance.addResourceBundle('zh', 'upgradeDialog', zhMessages)

const open$ = observable(false)

export const showUpgradeDialog = () => open$.set(true)
export const hideUpgradeDialog = () => open$.set(false)

export const UpgradeDialog = observer(() => {
  const [t] = useTranslation('upgradeDialog')
  const router = useRouter()
  return (
    <MgAlertDialog open={open$.get()} onOpenChange={(v) => open$.set(v)}>
      <MgAlertDialog.Portal>
        <MgAlertDialog.Content pos="relative">
          <XStack jc="flex-end">
            <MgButton.Default
              icon={<X size={14} />}
              br="$1"
              size="$2"
              onPress={hideUpgradeDialog}
            />
          </XStack>
          <YStack ai="center" w={400} $sm={{ w: '100%' }} gap="$3">
            <div className="h-52 w-52">
              <img
                src="https://api.mydreamboy.com/storage/v1/object/public/static/upgrade_premium.png"
                alt="upgrade_premium"
                className="h-full w-full"
              />
            </div>
            <Paragraph size="$4" fow="700">
              {t('title')}
            </Paragraph>
            <Paragraph ta="center" size="$3" col="$gray11">
              {t('desc')}
            </Paragraph>
            <XStack w="100%" jc="center" gap="$3">
              <MgButton.Default br="$2" size="$3" onPress={hideUpgradeDialog}>
                <MgButton.Text>{t('cancel')}</MgButton.Text>
              </MgButton.Default>
              <MgButton.Inverse
                br="$2"
                size="$3"
                onPress={() => {
                  router.push('/billings')
                  hideUpgradeDialog()
                }}
              >
                <MgButton.Text>{t('confirm')}</MgButton.Text>
              </MgButton.Inverse>
            </XStack>
          </YStack>
          <CardDecorator />
        </MgAlertDialog.Content>
      </MgAlertDialog.Portal>
    </MgAlertDialog>
  )
})
