import { Paragraph, ParagraphProps, XStack } from '@my/ui'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'solito/link'

import { getDefaultTagIdsMap } from '../utils/jsonToMap'

interface Props {
  combo: number[]
  isLink?: boolean
}

const tagsMap = getDefaultTagIdsMap()

export const TagComboLabel = ({ combo, isLink = true, ...props }: Props & ParagraphProps) => {
  const { i18n } = useTranslation()
  return (
    <XStack flex={1}>
      <ContextWrapper combo={combo} isLink={isLink}>
        <Paragraph col="$blue9" {...props}>
          #
          {combo
            .sort((a, b) => a - b)
            .map((id) =>
              i18n.resolvedLanguage === 'zh' ? tagsMap[id]?.cn_name : tagsMap[id]?.en_name
            )
            .join('_')}
        </Paragraph>
      </ContextWrapper>
    </XStack>
  )
}

function ContextWrapper({ children, isLink, combo }: { children: ReactNode } & Props) {
  if (isLink) {
    return (
      <Link href={`/explore/prompts/${encodeURIComponent(JSON.stringify([combo]))}`}>
        {children}
      </Link>
    )
  }

  return <>{children}</>
}
