import { AlertDialog, MgAlertDialog, MgButton, XStack, YStack } from '@my/ui'
import { X } from '@tamagui/lucide-icons'
import { useTranslation } from 'react-i18next'
import { useGeneratedImgWithOP } from '../context/GeneratedImgWithOPContext'

export const DeleteButton = () => {
  const [t] = useTranslation('generateImage', { keyPrefix: 'deleteButton' })
  const { onDelete } = useGeneratedImgWithOP()
  return (
    <MgAlertDialog>
      <MgAlertDialog.Trigger asChild>
        <XStack>
          <MgButton.Default size="$3" circular icon={<X />} />
        </XStack>
      </MgAlertDialog.Trigger>
      <MgAlertDialog.Portal>
        <MgAlertDialog.Content maxWidth={300} rowGap="$3" space>
          <AlertDialog.Title size="$8">{t('title')}</AlertDialog.Title>
          <AlertDialog.Description>{t('desc')}</AlertDialog.Description>
          <YStack space="$3" justifyContent="flex-end" mt="$5">
            <MgAlertDialog.Cancel asChild>
              <YStack>
                <MgButton.Inverse onPress={() => onDelete?.()}>
                  <MgButton.Text>{t('confirm')}</MgButton.Text>
                </MgButton.Inverse>
              </YStack>
            </MgAlertDialog.Cancel>
            <AlertDialog.Cancel asChild>
              <YStack>
                <MgButton.Default>
                  <MgButton.Text>{t('cancel')}</MgButton.Text>
                </MgButton.Default>
              </YStack>
            </AlertDialog.Cancel>
          </YStack>
        </MgAlertDialog.Content>
      </MgAlertDialog.Portal>
    </MgAlertDialog>
  )
}
