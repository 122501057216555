import { Observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { AnimatePresence, Circle, SizableText, TextProps, XStack, YStack, styled } from '@my/ui'
import { IconProps } from '@tamagui/helpers-icon'
import { LinearGradient } from '@tamagui/linear-gradient'
import { Image, Plus } from '@tamagui/lucide-icons'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'solito/router'

import { IconImagePlay } from './IconImagePlay'
import { IconUserScan } from './IconUserScan'

export const PlayTabItem = observer(({ open$ }: { open$: Observable<boolean> }) => {
  const [t] = useTranslation('home', { keyPrefix: 'mobileBottomTab' })
  const router = useRouter()
  return (
    <AnimatePresence>
      <XStack
        pos={'absolute' as any}
        top={-18}
        left="50%"
        zIndex={9999}
        transform="translate(-50%, 0)"
      >
        <Circle
          p="$2.5"
          size="$5"
          cur="pointer"
          rotate={open$.get() ? '45deg' : '0deg'}
          onPress={() => open$.set(!open$.peek())}
          animation="medium"
          animateOnly={['transform']}
        >
          <LinearGradient
            br="$12"
            width="100%"
            height="100%"
            pos="absolute"
            colors={['$blue6Light', '$green5Light', '$blue9Light']}
            start={[0, 0]}
            end={[1, 1]}
            zIndex={-1}
          />
          <XStack>
            <Plus strokeWidth={3} color="$color1" />
          </XStack>
        </Circle>
      </XStack>
      {open$.get() && (
        <XStack
          position="absolute"
          left={0}
          bottom={0}
          zIndex={99}
          w="100vw"
          h={220}
          bg="$color4"
          jc="center"
          gap="$5"
          pt="$3"
          opacity={1}
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
          animation="medium"
          btrr="$6"
          btlr="$6"
        >
          <SelectItemStack
            onPress={() => {
              router.push('/model/generator')
              setTimeout(() => open$.set(false), 99)
            }}
          >
            <IconWrapper>
              <IconUserScan {...iconProps} />
            </IconWrapper>
            <SizableText {...textProps}>{t('generateModel')}</SizableText>
          </SelectItemStack>
          <SelectItemStack
            onPress={() => {
              router.push('/model/play')
              setTimeout(() => open$.set(false), 99)
            }}
          >
            <IconWrapper>
              <Image {...iconProps} />
            </IconWrapper>
            <SizableText {...textProps}>{t('generateImage')}</SizableText>
          </SelectItemStack>
          <SelectItemStack
            onPress={() => {
              router.push('/image-editor')
              setTimeout(() => open$.set(false), 99)
            }}
          >
            <IconWrapper>
              <IconImagePlay className="h-7 w-7 stroke-gray-700 stroke-2" />
            </IconWrapper>
            <SizableText {...textProps}>{t('imageEditor')}</SizableText>
          </SelectItemStack>
        </XStack>
      )}
    </AnimatePresence>
  )
})
const iconProps: IconProps = { size: '$2', color: '$gray11' }
const textProps: TextProps = { fos: '$1', color: '$color' }

const SelectItemStack = styled(YStack, {
  gap: '$2',
  ai: 'center',
  enterStyle: { scale: 0 },
  exitStyle: { scale: 0 },
  scale: 1,
  animation: 'medium',
})

const IconWrapper = styled(XStack, {
  bg: '$color1',
  p: '$3',
  br: '$6',
})
