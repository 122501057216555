import { observable } from "@legendapp/state";
import { useObserve } from "@legendapp/state/react";
import { CommentMetaDataFragment } from "@my/supabase/graphql/gql/graphql";
import { api } from "app/utils/api";
import { useMemo, useState } from "react";

const cache$ = observable({
  likes: [] as string[],
  dislikes: [] as string[]
})

const addLike = (id: string) => {
  const likes = cache$.likes.get()
  const dislikes = cache$.dislikes.get()
  if (!likes.includes(id)) {
    cache$.likes.push(id)
  }
  if (dislikes.includes(id)) {
    cache$.dislikes.splice(dislikes.findIndex(d => d === id), 1)
  }
}


const addUnlike = (id: string) => {
  const likes = cache$.likes.get()
  const dislikes = cache$.dislikes.get()
  if (!dislikes.includes(id)) {
    cache$.dislikes.push(id)
  }
  if (likes.includes(id)) {
    cache$.likes.splice(likes.findIndex(d => d === id), 1)
  }
}

const resetLike = (id: string) => {
  const likes = cache$.likes.get()
  const dislikes = cache$.dislikes.get()
  if (likes.includes(id)) {
    cache$.likes.splice(likes.findIndex(d => d === id), 1)
  }
  if (dislikes.includes(id)) {
    cache$.dislikes.splice(dislikes.findIndex(d => d === id), 1)
  }
}

export const useToggleCommentLike = (comment: CommentMetaDataFragment) => {
  const [like, setLike] = useState(!!comment.comment_likesCollection?.edges.length);
  const trpc = api.useContext()

  const likeCount = useMemo(() => {
    const databaseLike = !!comment.comment_likesCollection?.edges.length
    const databaseLikeCount = (comment.likes ?? 0)
    if (databaseLike === like) return databaseLikeCount
    else if (like) return databaseLikeCount + 1
    else return databaseLikeCount - 1
  }, [comment.comment_likesCollection?.edges.length, comment.likes, like])

  useObserve(() => {
    if (cache$.likes.get().includes(comment.id)) {
      setLike(true)
    }
    if (cache$.dislikes.get().includes(comment.id)) {
      setLike(false)
    }
  })


  const toggleLike = async () => {
    const commentId = comment.id
    if (like) {
      addUnlike(commentId)
      await trpc.comment.unlike.fetch({ id: commentId }).catch(() => resetLike(commentId))
    } else {
      addLike(commentId)
      await trpc.comment.like.fetch({ id: commentId }).catch(() => resetLike(commentId))
    }
  }



  return {
    like,
    likeCount,
    toggleLike
  }
}