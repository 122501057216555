import { observable } from '@legendapp/state'
import { IModel } from 'app/features/models'
import Router from 'next/router'

import { IPost } from '../../typings/post'

Router.events.on('routeChangeStart', () => {
  store$.show.set(false)
})

type Store = {
  show: boolean
  post: IPost | null
  model: IModel | null
}

export const store$ = observable<Store>({
  show: false,
  post: null,
  model: null,
})

export const hideModal = () => {
  store$.set({
    show: false,
    post: null,
    model: null,
  })
}
