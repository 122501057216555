import { observer, useSelector } from '@legendapp/state/react'
import { StartChatButton } from 'app/features/chat/StartChatButton/StartChatButton'
import { Comments } from 'app/features/comments/CommentList/Comments'
import { CommentEditor } from 'app/features/comments/Editor/Editor'
import { UserCreatedBadge } from 'app/features/users/UserCreatedBadge/UserCreatedBadge'
import { useIsMobile } from 'app/hooks/useMediaQuery'
import { IconEye } from 'app/icons/IconEye'
import { IconEyeSlash } from 'app/icons/IconEyeSlash'
import { format, isThisYear } from 'date-fns'

import { useIsPrivatePost, useIsSelfPost } from '../../hooks/usePostStatus'
import { store$ } from '../context/PostDetailModelContext'
import { GenerateMoreButton } from './GenerateMore/GenerateMoreButton'
import { PostContent } from './PostContent'
import { PostDetailMenu } from './PostDetailMenu/PostDetailMenu'
import { ShareDialog } from './PostDetailMenu/components/ShareDialog'
import { PostDetailTag } from './PostDetailTag'
import { PostInteractions } from './PostInteractions'
import { PostModalSlide } from './PostModalSlide'
import { PostModelInfo } from './PostModelInfo'

export const PostDetailWrapper = observer(() => {
  const { post, model } = useSelector(() => store$.get())
  const isMobile = useIsMobile()
  if (!post || !model) return null
  return (
    <>
      {isMobile ? <MobileWrapper /> : <WebWrapper />}
      <ShareDialog />
    </>
  )
})

const WebWrapper = () => {
  const { post, model } = useSelector(() => store$.get())
  const isSelfPost = useIsSelfPost(store$.post.get())
  if (!post || !model) return null

  return (
    <div className="flex max-h-full w-full items-center justify-center">
      <div className="flex max-h-full w-full max-w-[1280px] rounded-xl border border-gray-100">
        <div className="w-[55%]">
          <PostModalSlide />
        </div>
        <div className="custom-scrollbar relative flex max-h-full w-[45%] flex-col border-l border-gray-100 py-6">
          <div className="flex w-full items-center justify-between border-b border-gray-100 px-6 pb-3">
            <PostModelInfo />
            {isSelfPost && <PostDetailMenu />}
          </div>
          <div className="my-6 flex-1 overflow-y-auto">
            <div className="flex flex-col gap-3 px-6">
              <PostContent />
              <PostDetailTag />
              {(!model.private || isSelfPost) && (
                <div className="flex gap-2">
                  <GenerateMoreButton />
                  <StartChatButton modelId={model.id!} />
                </div>
              )}
              {post.creatorVO && (
                <UserCreatedBadge
                  id={post.creatorVO.profiles.id}
                  avatar={post.creatorVO.profiles.avatar_url ?? undefined}
                  name={post.creatorVO.profiles.name ?? undefined}
                />
              )}
              <PostTimeAndScope />
              <Comments topic={post.id!} />
            </div>
          </div>
          <div className="bg-coal-600 flex w-full flex-col border-t border-gray-100 px-6 py-3">
            <PostInteractions />
            <CommentEditor topic={post.id!} />
          </div>
        </div>
      </div>
    </div>
  )
}

const MobileWrapper = () => {
  const { post, model } = useSelector(() => store$.get())
  const isSelfPost = useIsSelfPost(post!)
  if (!post || !model) return null

  return (
    <>
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="relative z-10 flex w-full flex-1 flex-col gap-4">
          <div className="border-b border-solid px-4">
            <PostModalSlide />
          </div>
          <div className="flex flex-col gap-3.5 px-5 pb-80">
            <PostContent />
            <PostDetailTag />
            {(!model.private || isSelfPost) && (
              <div className="flex gap-2">
                <GenerateMoreButton />
                <StartChatButton modelId={model.id!} />
              </div>
            )}
            {post.creatorVO && (
              <UserCreatedBadge
                id={post.creatorVO.profiles.id}
                avatar={post.creatorVO.profiles.avatar_url ?? undefined}
                name={post.creatorVO.profiles.name ?? undefined}
              />
            )}
            <PostTimeAndScope />
            <Comments topic={post.id!} />
          </div>
        </div>
      </div>
      <div className="bg-coal-600 fixed bottom-0 z-50 box-border flex w-full flex-col border-t border-solid border-gray-200 px-5 pb-5">
        <div className="flex items-center justify-end">
          <PostInteractions />
        </div>
        <div className="w-full">
          <CommentEditor topic={post.id!} />
        </div>
      </div>
    </>
  )
}

const PostTimeAndScope = observer(() => {
  const post = store$.post.get()
  const isPrivatePost = useIsPrivatePost(post)
  const isSelfPost = useIsSelfPost(post)
  const createdAt = post.created_at

  return (
    <div className="flex items-center gap-2">
      {isSelfPost &&
        (isPrivatePost ? (
          <IconEyeSlash className="h-4 w-4 text-gray-600" />
        ) : (
          <IconEye className="h-4 w-4 text-gray-600" />
        ))}
      {createdAt && (
        <p className="text-xs text-gray-600">
          {format(createdAt, isThisYear(createdAt) ? 'MM-dd HH:mm' : 'yyyy-MM-dd HH:mm')}
        </p>
      )}
    </div>
  )
})
