import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M16.584 22.35H6.196a4.516 4.516 0 0 1-4.516-4.506V7.456A4.515 4.515 0 0 1 6.196 2.94h7.567a.76.76 0 0 1 0 1.512H6.196a3.004 3.004 0 0 0-3.004 3.004v10.388a3.004 3.004 0 0 0 3.004 2.994h10.388a3.004 3.004 0 0 0 3.004-2.994v-7.702a.76.76 0 1 1 1.512 0v7.702a4.516 4.516 0 0 1-4.516 4.506Zm-2.84-11.063L20.81 4.23v2.34a.761.761 0 1 0 1.511 0V2.372a.76.76 0 0 0-.722-.722h-4.207a.76.76 0 0 0 0 1.511h2.35l-7.068 7.058a.77.77 0 0 0 0 1.068.752.752 0 0 0 1.069 0Z"
    />
  </svg>
)
export const IconExitRightCorner = memo(SvgComponent)
