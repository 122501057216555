import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M19.343 8.075a3.408 3.408 0 1 1 0-6.815 3.408 3.408 0 0 1 0 6.815Zm0-5.297a1.9 1.9 0 1 0 1.9 1.9 1.9 1.9 0 0 0-1.9-1.93v.03Zm-5.83 19.007 1.075-1.528a.584.584 0 0 1 .473-.241h1.527a5.448 5.448 0 0 0 5.408-5.468V9.361a.754.754 0 1 0-1.508 0v5.187a3.94 3.94 0 0 1-3.93 3.93h-1.497a2.01 2.01 0 0 0-1.699.885l-1.075 1.527a.824.824 0 0 1-1.297 0l-1.056-1.527a2.07 2.07 0 0 0-1.698-.885H6.688a3.94 3.94 0 0 1-3.93-3.93V7.401a3.93 3.93 0 0 1 3.93-3.93h7.96a.754.754 0 1 0 0-1.507h-7.96A5.448 5.448 0 0 0 1.25 7.4v7.147a5.448 5.448 0 0 0 5.438 5.438h1.528a.582.582 0 0 1 .472.241l1.076 1.528a2.291 2.291 0 0 0 3.749 0v.03Zm-5.73-11.358a1.006 1.006 0 1 0 0 2.011 1.006 1.006 0 0 0 0-2.011Zm3.85 0a1.005 1.005 0 1 0 0 2.01 1.005 1.005 0 0 0 0-2.01Zm0 0a1.005 1.005 0 1 0 0 2.01 1.005 1.005 0 0 0 0-2.01Zm3.86 0a1.006 1.006 0 1 0 0 2.011 1.006 1.006 0 0 0 0-2.011Z"
    />
  </svg>
)
export const IconChat = memo(SvgComponent)
