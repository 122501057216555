import { cn } from '@my/magic-ui/src'
import { Button } from 'app/components/Button'
import { useCheckLogin } from 'app/features/auth/hooks/useCheckLogin'
import { api } from 'app/utils/api'
import { useTranslation } from 'react-i18next'

import { modelSubscribeStore } from '../../../stores/modelSubscribe'
import { useModelSubscribeButton } from '../contexts/ModelSubscribeButtonContext'

export const SubscribeButton = () => {
  const [t] = useTranslation('modelSubscribeButton')

  const { subscribeButtonClassName, model, afterSubscribe } = useModelSubscribeButton()

  const subscribe = api.model.subscribe.useMutation()
  const { checkLogin } = useCheckLogin()
  const onSubscribe = () => {
    if (checkLogin()) {
      modelSubscribeStore.addSubscribe(model.id)
      subscribe.mutateAsync({ id: model.id }).catch(() => {
        modelSubscribeStore.removeSubscribe(model.id)
      })
      afterSubscribe?.()
    }
  }

  return (
    <Button
      mode="white"
      showArrow={false}
      className={cn('w-fit px-4 py-2 text-sm text-black', subscribeButtonClassName)}
      onClick={onSubscribe}
    >
      {t('subscribe')}
    </Button>
  )
}
