import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m11.775 15.913-2.833 2.454a.833.833 0 0 1-.12.08l-.1.05a.629.629 0 0 1-.538 0l-.09-.05-.08-.06-3.082-2.464a.768.768 0 0 1-.12-1.068.748.748 0 0 1 1.057-.12l1.836 1.487v-5.168a.748.748 0 0 1 1.497 0v5.088l1.606-1.386a.738.738 0 0 1 .998.08.768.768 0 0 1-.03 1.077Zm10.975.359a6.415 6.415 0 0 1-6.355 6.455h-8.79a6.415 6.415 0 0 1-6.355-6.455v-8.54a6.415 6.415 0 0 1 6.355-6.455h6.445a.65.65 0 0 1 .19 0h.08c.077.033.148.08.21.14l7.98 8.11a.758.758 0 0 1 .19.719c.006.05.006.1 0 .15l.05 5.876Zm-7.982-9.448a2.554 2.554 0 0 0 2.525 2.564h2.903l-5.447-5.517.02 2.953Zm6.515 9.448v-5.367h-3.99a4.06 4.06 0 0 1-3.991-4.081v-3.99H7.605a4.899 4.899 0 0 0-4.858 4.898v8.54a4.9 4.9 0 0 0 4.858 4.938h8.8a4.9 4.9 0 0 0 4.858-4.938h.02Z"
    />
  </svg>
)
export const IconFileDown = memo(SvgComponent)
