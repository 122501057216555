import { observer } from '@legendapp/state/react'
import { Paragraph, XStack } from '@my/ui'
import { store$ } from '../context/PostDetailModelContext'

export const PostContent = observer(() => {
  const post = store$.post.get()
  const content = post.content

  if (!content) return null
  return (
    <XStack>
      <Paragraph>{content}</Paragraph>
    </XStack>
  )
})
