import { observer, useObservable } from '@legendapp/state/react'
import { SizableText, XStack, YStack } from '@my/ui'
import { IconProps } from '@tamagui/helpers-icon'
import { Compass, MessageCircle, Sparkles, User2 } from '@tamagui/lucide-icons'
import { useIsScrollUp } from 'app/utils/hooks/useIsScrollUp'
import { getExploreModelLink, getExplorePostLink } from 'app/utils/model-version'
import { useRouter as useNextRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { PlayTabItem } from './components/PlayTabItem'

export const MobileBottomTab = observer(() => {
  const [t] = useTranslation('home')
  const { isScrollUp$ } = useIsScrollUp(true)
  const open$ = useObservable(false)
  return (
    <XStack
      pos={'fixed' as any}
      bottom={0}
      left={0}
      right={0}
      zIndex={99}
      jc="space-between"
      pt="$3"
      pb="$6"
      px="$2"
      btw="$0.5"
      boc="$gray4"
      bg="$color1"
      gap="$8"
      $gtSm={{ display: 'none' }}
      opacity={isScrollUp$.get() || open$.get() ? 1 : 0.7}
    >
      <XStack jc="space-around" f={1} w="50%">
        <TabItem icon={Compass} text={t('explore')} path={getExplorePostLink()} />
        <TabItem icon={Sparkles} text={t('model')} path={getExploreModelLink()} />
      </XStack>
      <XStack jc="space-around" f={1} w="50%">
        <TabItem icon={MessageCircle} text={t('chat')} path="/chat" />
        <TabItem icon={User2} text={t('profile')} path="/profile" />
      </XStack>

      <PlayTabItem open$={open$} />
    </XStack>
  )
})

const TabItem = ({
  icon: Icon,
  text,
  path,
}: {
  icon: React.FC<IconProps>
  text: string
  path: string
}) => {
  const router = useNextRouter()
  const isActive = router.pathname === path
  return (
    <YStack gap="$1.5" ai="center" scale={isActive ? 1 : 0.9} onPress={() => router.push(path)}>
      <Icon color={isActive ? '$blue9' : '$color'} />
      <SizableText fow="600" color={isActive ? '$blue9' : '$color'} fos={13}>
        {text}
      </SizableText>
    </YStack>
  )
}
