import {
  Adapt,
  AdaptProps,
  Popover,
  PopoverProps,
  PopperContentProps,
  withStaticProperties,
} from 'tamagui'

export type MgPopoverProps = {}

const Wrapper: React.FC<PopoverProps> = (props) => {
  return <Popover {...props}>{props.children}</Popover>
}

const AdaptWrapper = (props: AdaptProps) => {
  return (
    <Adapt platform="web" when="sm" {...props}>
      <Popover.Sheet zIndex={100000000} modal dismissOnSnapToBottom>
        <Popover.Sheet.Frame>
          <Popover.Sheet.ScrollView>
            <Adapt.Contents />
          </Popover.Sheet.ScrollView>
        </Popover.Sheet.Frame>
        <Popover.Sheet.Overlay zIndex={100} />
      </Popover.Sheet>
    </Adapt>
  )
}

const ContentWrapper = (props: PopperContentProps) => {
  return (
    <Popover.Content
      bw={1}
      boc="$borderColor"
      enterStyle={{ x: 0, y: -10, o: 0 }}
      exitStyle={{ x: 0, y: -10, o: 0 }}
      x={0}
      y={0}
      o={1}
      animation={[
        'quick',
        {
          opacity: {
            overshootClamping: true,
          },
        },
      ]}
      elevate
      zIndex={100000000}
      {...props}
    >
      {props.children}
    </Popover.Content>
  )
}

export const MgPopover = withStaticProperties(Wrapper, {
  Adapt: AdaptWrapper,
  Content: ContentWrapper,
  Trigger: Popover.Trigger,
  ScrollView: Popover.ScrollView,
})
