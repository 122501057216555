export const getBase64ByUrl = async (url: string): Promise<string> => {
  try {
    const response = await fetch(url)

    // 检查响应状态
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const blob = await response.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result)
        } else {
          reject(new Error('Failed to convert to base64'))
        }
      }
      reader.onerror = (error) => reject(new Error(`FileReader error: ${error}`))
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    // 更详细的错误信息
    console.error('Image conversion error:', error)
    throw new Error(
      `Failed to fetch image: ${error instanceof Error ? error.message : String(error)}`
    )
  }
}

export const getBase64ByFile = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(new Error(`FileReader error: ${error}`))
    reader.readAsDataURL(file)
  })
}
