import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M14.214 12.646c.01-.115.01-.23 0-.345a.794.794 0 0 1 1.24-.763.762.762 0 0 1 .306.513c.016.198.016.397 0 .595a3.76 3.76 0 0 1-3.76 3.76h-.418a.783.783 0 0 1-.69-.866.773.773 0 0 1 .857-.69 2.217 2.217 0 0 0 2.465-2.204Zm6.884-5.045a.797.797 0 0 0-1.201 1.044 6.685 6.685 0 0 1 1.786 4.043c0 3.562-4.69 8.158-9.662 8.158a8.922 8.922 0 0 1-3.865-1.045.783.783 0 1 0-.7 1.4 10.446 10.446 0 0 0 4.565 1.17c5.453 0 11.229-4.993 11.229-9.725a8.19 8.19 0 0 0-2.152-5.045Zm1.045-4.523L3.038 22.058a.773.773 0 0 1-.544.23.825.825 0 0 1-.564-.23.794.794 0 0 1 0-1.118l2.09-2.09C1.93 16.93.75 14.674.75 12.668c.042-4.659 5.923-9.746 11.27-9.746a11.26 11.26 0 0 1 6.018 1.985l2.966-2.946a.783.783 0 1 1 1.045 1.118h.094ZM5.21 17.702l3.479-3.458a3.729 3.729 0 0 1 5.003-4.993l3.26-3.238a9.6 9.6 0 0 0-4.931-1.525c-4.419 0-9.662 4.387-9.662 8.158 0 1.556 1.065 3.458 2.81 5.077l.041-.021Zm4.66-4.649 2.558-2.559a2.69 2.69 0 0 0-.407 0 2.194 2.194 0 0 0-2.194 2.194c.002.123.016.245.042.365Z"
    />
  </svg>
)
export const IconEyeSlash = memo(SvgComponent)
