import { cn } from '@my/magic-ui/src'

export const ActionIcon = ({
  icon,
  onClick,
  rounded,
  className,
  square,
  disabled,
}: {
  icon: React.ReactNode
  onClick?: () => void
  className?: string
  rounded?: boolean
  square?: boolean
  disabled?: boolean
}) => {
  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={cn(
        'active:text-primary-active cursor-pointer p-2',
        rounded && 'bg-light hover:bg-light-light active:bg-light-active rounded-full',
        square && 'hover:bg-primary-clarity hover:bg-coal-100 rounded-lg',
        disabled && 'cursor-not-allowed opacity-50 active:text-inherit',
        className
      )}
    >
      {icon}
    </div>
  )
}
