import { useUser } from 'app/utils/useUser'

import { deletedPostIds$, privatePostIds$, publicPostIds$ } from '../stores/operatedPosts'
import { IPost } from '../typings/post'

export const useIsPrivatePost = (post: IPost) => {
  if (!post) return false // solve mobile crash
  return (
    (post.private && !publicPostIds$.get().has(Number(post.id))) ||
    privatePostIds$.get().has(Number(post.id))
  )
}

export const isPrivatePost = (post: IPost) =>
  post.private ?? privatePostIds$.get().has(Number(post.id))

export const useIsDeletedPost = (post: IPost) => {
  return deletedPostIds$.get().has(Number(post.id))
}

export const useIsSelfPost = (post: IPost) => {
  const { user } = useUser()
  return post?.creator === user?.id
}
