import { Show, useObservable, useObserve } from '@legendapp/state/react'
import { i18nInstance } from 'app/utils/i18n'
import { useEffect } from 'react'

import { SubscribeButton } from './components/SubscribeButton'
import { UnSubscribeButton } from './components/UnSubscribeButton'
import {
  ModelSubscribeButtonProps,
  ModelSubscribeButtonProvider,
} from './contexts/ModelSubscribeButtonContext'
import enMessages from '../../locales/en/modelSubscribeButton.json'
import zhMessages from '../../locales/zh/modelSubscribeButton.json'
import { modelSubscribeStore } from '../../stores/modelSubscribe'

i18nInstance.addResourceBundle('en', 'modelSubscribeButton', enMessages)
i18nInstance.addResourceBundle('zh', 'modelSubscribeButton', zhMessages)

export const ModelSubscribeButton = (props: ModelSubscribeButtonProps) => {
  const subscribed$ = useObservable(props.subscribed)

  useEffect(() => {
    subscribed$.set(props.subscribed)
    if (modelSubscribeStore.state$.subscribe.get().includes(props.model.id)) {
      subscribed$.set(true)
    }
    if (modelSubscribeStore.state$.unsubscribe.get().includes(props.model.id)) {
      subscribed$.set(false)
    }
  }, [props.model.id, props.subscribed, subscribed$])

  useObserve(() => {
    if (modelSubscribeStore.state$.subscribe.get().includes(props.model.id)) {
      subscribed$.set(true)
    }
    if (modelSubscribeStore.state$.unsubscribe.get().includes(props.model.id)) {
      subscribed$.set(false)
    }
  })

  return (
    <ModelSubscribeButtonProvider {...props}>
      <Show if={subscribed$} else={() => <SubscribeButton />}>
        {() => <UnSubscribeButton />}
      </Show>
    </ModelSubscribeButtonProvider>
  )
}
