import { observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { useMemoizedFn } from 'ahooks'
import { ShareModal } from 'app/features/socialMedia/components/ShareModal'
import { api } from 'app/utils/api'
import { isSameDayByCompareStr } from 'app/utils/date'
import { useUser } from 'app/utils/useUser'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { store$ } from '../../../context/PostDetailModelContext'

const open$ = observable(false)

export const showShareDialog = () => open$.set(true)

export const ShareDialog = observer(() => {
  const { isLogin } = useUser()
  const link = `${location.origin}/s/post/${store$.post.id.get()}`
  const shareInfo = api.user.shareInfo.useQuery(undefined, { enabled: isLogin })
  const afterShare = api.user.afterShare.useMutation()

  const onShare = useMemoizedFn(() => {
    if (isLogin) {
      afterShare
        .mutateAsync({ date: format(new Date(), 'yyyy-MM-dd') })
        .then(() => shareInfo.refetch())
    }
  })

  return (
    <ShareModal
      open={open$.get()}
      onClose={() => open$.set(false)}
      url={link}
      title="Unleashing Desire: Discover the Allure of #MyDreamBoyAI  🔥💘"
      afterShare={onShare}
    >
      <ShareInfo />
    </ShareModal>
  )
})

const ShareInfo = () => {
  const [t] = useTranslation('postDetail', { keyPrefix: 'shareDialog' })

  const { isLogin } = useUser()

  const shareInfo = api.user.shareInfo.useQuery(undefined, { enabled: isLogin })

  const amount = shareInfo.data?.share_date
    ? isSameDayByCompareStr({
        date: format(new Date(), 'yyyy-MM-dd'),
        origin: shareInfo.data.share_date,
      })
      ? (shareInfo.data.amount ?? 0)
      : 0
    : 0

  if (!shareInfo.isSuccess) return null

  return (
    <div className="p-3 text-xs text-gray-600">
      <p>
        {t('hint')}
        <span className="text-primary">({amount}/2)</span>
      </p>
    </div>
  )
}
