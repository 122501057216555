//https://gist.github.com/SimeonGriggs/7071958b8a629faf9137734aec713a0c

import tailwindConfig from '@my/magic-ui/tailwind.config'
import { useEffect, useLayoutEffect, useState } from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'

const fullConfig = resolveConfig(tailwindConfig)
export const {
  theme: { screens },
} = fullConfig

// 创建一个同构的 useLayoutEffect
const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

export const useMediaQuery = (query: keyof typeof screens): boolean => {
  const mediaQuery = `(min-width: ${screens[query]})`
  const [isMatch, setMatch] = useState<boolean>(false) // 统一使用 false 作为初始值
  const onChange = (e: MediaQueryListEvent) => setMatch(e.matches)

  useIsomorphicLayoutEffect(() => {
    const matchQueryList = window.matchMedia(mediaQuery)
    // 立即设置初始值
    setMatch(matchQueryList.matches)
    matchQueryList.addEventListener('change', onChange)
    return () => matchQueryList.removeEventListener('change', onChange)
  }, [mediaQuery])

  return isMatch
}

// 使用 "大于等于md" 判断是否为桌面
export const useIsDesktop = () => useMediaQuery('md')

// 使用 "小于md" 判断是否为移动设备
export const useIsMobile = () => !useIsDesktop()

export const useIsXlScreen = () => useMediaQuery('xl')

export const isXlScreen = () => {
  if (typeof window === 'undefined') return false
  return window.matchMedia(`(min-width: ${screens.xl})`).matches
}
