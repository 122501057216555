import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M12 2.433a9.568 9.568 0 1 1 0 19.135 9.568 9.568 0 0 1 0-19.135Zm-.952 11.081-3.141-3.151a1.023 1.023 0 0 0-.675-.277.962.962 0 0 0-.676 1.638l3.817 3.827a1.023 1.023 0 0 0 1.361 0l5.73-5.74a.962.962 0 0 0-.675-1.638 1.023 1.023 0 0 0-.676.276l-5.054 5.117M12 1a11 11 0 1 0 11 11A11.01 11.01 0 0 0 12 1Z"
    />
  </svg>
)
export const IconCheckCircle = memo(SvgComponent)
