import TagsV1Json from './tags_v1.json'
import { ITagInfoV1 } from '../typings/tag'

export const TagsV1 = TagsV1Json as any as { [key: number]: ITagInfoV1 }

export const MaterialV1Id = 23
export const MaterialV1RandomId = -99
export const ColorAttV1Id = 24
export const ColorV1RandomId = -98
export const ResolutionId = -97
export const UserLibraryId = -96
export const CustomizePromptId = -95

export const PortraintV1Id = 212
export const CowboyShotV1Id = 304
export const FaceV1Id = 5776
export const PenisV1Id = 5088
export const AssV1Id = 5099

export const NudeV1Id = 5089

export const getCombosIdByPrompt = (prompt: string) => {
  const arr = prompt.split(',')
  return arr
    .map((str) => Object.values(TagsV1).find((tag) => tag.tagName === str)!.tagId)
    .map((id) => [id])
}
