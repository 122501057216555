import { observer } from '@legendapp/state/react'
import { XStack } from '@my/ui'
import { ImageProps } from '@my/ui/types'
import { i18nInstance } from 'app/utils/i18n'
import { useUser } from 'app/utils/useUser'

import { GeneratedImg } from './GeneratedImg'
import { AsAvatarButton } from './components/AsAvatarButton'
import { DeleteButton } from './components/DeleteButton'
import { GeneratedImgWithOPProvider, IProps } from './context/GeneratedImgWithOPContext'
import enMessages from '../locale/en/generateImage.json'
import zhMessages from '../locale/zh/generateImage.json'
i18nInstance.addResourceBundle('en', 'generateImage', enMessages)
i18nInstance.addResourceBundle('zh', 'generateImage', zhMessages)

type Props = Omit<ImageProps, 'source'> & IProps

export const GeneratedImgWithOP = observer(
  ({
    image,
    post,
    model,
    showAvatarBtn,
    showTemplateBtn,
    onDelete,
    showDeleteBtn,
    ...props
  }: Props) => {
    const { isLogin } = useUser()
    return (
      <GeneratedImgWithOPProvider
        image={image}
        post={post}
        model={model}
        showAvatarBtn={showAvatarBtn ?? true}
        showTemplateBtn={showTemplateBtn ?? true}
        showDeleteBtn={showDeleteBtn ?? false}
        onDelete={onDelete}
      >
        <XStack width="100%" height="100%" position="relative">
          <GeneratedImg item={image} {...props} />
          <XStack
            position="absolute"
            right={24}
            top={24}
            gap="$3"
            $sm={{ right: 12, top: 12 }}
            ai="center"
          >
            {isLogin && <AsAvatarButton />}
            {showDeleteBtn && <DeleteButton />}
          </XStack>
        </XStack>
      </GeneratedImgWithOPProvider>
    )
  }
)
