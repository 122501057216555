import {
  AlertDialog,
  AlertDialogContentProps,
  AlertDialogPortal,
  AlertDialogPortalProps,
  AlertDialogProps,
  withStaticProperties,
} from 'tamagui'

export type MgAlertDialogProps = AlertDialogProps

const Wrapper = (props: MgAlertDialogProps) => {
  return <AlertDialog {...props}>{props.children}</AlertDialog>
}

const MgAlertDialogPortal = (props: AlertDialogPortalProps) => {
  return (
    <AlertDialogPortal {...props}>
      <AlertDialog.Overlay
        key="MgAlertDialogOverlay"
        animation="quick"
        opacity={0.5}
        enterStyle={{ opacity: 0 }}
        exitStyle={{ opacity: 0 }}
      />
      {props.children}
    </AlertDialogPortal>
  )
}

const MgAlertDialogContent = (props: AlertDialogContentProps) => {
  return (
    <AlertDialog.Content
      bordered
      elevate
      key="content"
      animation={[
        'quick',
        {
          opacity: {
            overshootClamping: true,
          },
        },
      ]}
      enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
      exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
      x={0}
      scale={1}
      opacity={1}
      y={0}
      borderWidth="$0"
      {...props}
    >
      {props.children}
    </AlertDialog.Content>
  )
}

export const MgAlertDialog = withStaticProperties(Wrapper, {
  Portal: MgAlertDialogPortal,
  Content: MgAlertDialogContent,
  Trigger: AlertDialog.Trigger,
  Cancel: AlertDialog.Cancel,
})
