import { observable } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { Button } from 'app/components/Button'
import Modal from 'app/components/Modal'
import { IconCheckCircle } from 'app/icons/IconCheckCircle'
import { api } from 'app/utils/api'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const modalShow$ = observable(false)

export const showContactUsModal = () => modalShow$.set(true)

export const ContactUsModal = observer(() => {
  const [t] = useTranslation('home', { keyPrefix: 'contact' })
  const isSubmitted$ = useObservable(false)

  const onClsoe = () => {
    modalShow$.set(false)
    isSubmitted$.set(false)
  }

  return (
    <Modal isOpen={modalShow$.get()} onClose={onClsoe}>
      <div className="w-[80vw] p-4 md:w-[500px]">
        <h2 className="mb-6 mt-1 text-xl font-bold">{t('title')}</h2>

        <AnimatePresence mode="wait">
          {isSubmitted$.get() ? (
            <FeedbackSuccess />
          ) : (
            <FeedbackForm onSubmit={() => isSubmitted$.set(true)} />
          )}
        </AnimatePresence>
      </div>
    </Modal>
  )
})

const FeedbackForm = ({ onSubmit }: { onSubmit: (feedback: string) => void }) => {
  const [feedback, setFeedback] = useState('')
  const [errMsg, setErrMsg] = useState('')

  const [t] = useTranslation('home', { keyPrefix: 'contact' })
  const feedbackMutation = api.user.createFeedback.useMutation()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!feedback.trim()) {
      setErrMsg('Feedback message required')
      return
    }

    try {
      await feedbackMutation.mutateAsync({ content: feedback })
      setFeedback('')
    } catch (error) {
      setErrMsg('Failed to submit, please try again later')
    }
    onSubmit(feedback)
  }

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="mx-auto w-full rounded-lg shadow-lg"
    >
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="feedback" />
          <motion.textarea
            id="feedback"
            value={feedback}
            onChange={(e) => {
              setFeedback(e.target.value)
              setErrMsg('')
            }}
            whileFocus={{ scale: 1.01 }}
            className="focus:ring-primary w-full resize-none whitespace-pre-wrap break-words rounded-md border border-solid border-gray-200 p-3 transition-all focus:border-transparent focus:ring-2"
            rows={8}
            placeholder={t('desc')}
          />
        </div>

        {errMsg && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-danger text-sm"
          >
            {errMsg}
          </motion.p>
        )}

        <Button
          type="submit"
          loading={feedbackMutation.isLoading}
          showArrow={false}
          className="py-2 text-base"
          disabled={!feedback.trim() || feedbackMutation.isLoading}
        >
          {t('okText')}
        </Button>
      </form>
    </motion.div>
  )
}

const FeedbackSuccess = () => {
  const [t] = useTranslation('home', { keyPrefix: 'contact' })
  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="mx-auto w-full rounded-lg shadow-lg"
    >
      <div className="flex min-h-[300px] flex-col items-center justify-center gap-2">
        <IconCheckCircle className="text-primary h-16 w-16" />
        <h2 className="text-2xl font-bold">{t('successTitle')}</h2>
        <p className="text-sm text-gray-500">{t('successDesc')}</p>
      </div>
    </motion.div>
  )
}
