import { observable } from '@legendapp/state'

export const modelSubscribeStore = {
  state$: observable({
    subscribe: [] as string[],
    unsubscribe: [] as string[],
  }),

  addSubscribe(id: string) {
    const subscribes = modelSubscribeStore.state$.subscribe.peek()
    const unsubscribes = modelSubscribeStore.state$.unsubscribe.peek()
    if (!subscribes.includes(id)) {
      modelSubscribeStore.state$.subscribe.push(id)
    }
    if (unsubscribes.includes(id)) {
      modelSubscribeStore.state$.unsubscribe.splice(
        unsubscribes.findIndex((d) => d === id),
        1
      )
    }
  },

  addUnsubscribe(id: string) {
    const subscribes = modelSubscribeStore.state$.subscribe.peek()
    const unsubscribes = modelSubscribeStore.state$.unsubscribe.peek()
    if (!unsubscribes.includes(id)) {
      modelSubscribeStore.state$.unsubscribe.push(id)
    }
    if (subscribes.includes(id)) {
      modelSubscribeStore.state$.subscribe.splice(
        subscribes.findIndex((d) => d === id),
        1
      )
    }
  },

  removeSubscribe(id: string) {
    const subscribes = modelSubscribeStore.state$.subscribe.peek()
    const unsubscribes = modelSubscribeStore.state$.unsubscribe.peek()
    if (subscribes.includes(id)) {
      modelSubscribeStore.state$.subscribe.splice(
        subscribes.findIndex((d) => d === id),
        1
      )
    }
    if (unsubscribes.includes(id)) {
      modelSubscribeStore.state$.unsubscribe.splice(
        unsubscribes.findIndex((d) => d === id),
        1
      )
    }
  },
}
