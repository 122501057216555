import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M12 23a11 11 0 1 1 11-11 11.01 11.01 0 0 1-11 11Zm0-20.465A9.465 9.465 0 1 0 21.465 12 9.476 9.476 0 0 0 12 2.535Zm-1.248 9.404a1.371 1.371 0 1 0 2.742 0 1.371 1.371 0 0 0-2.742 0Zm-5.117 0a1.371 1.371 0 1 0 1.351-1.372 1.371 1.371 0 0 0-1.371 1.372h.02Zm10.233 0a1.371 1.371 0 1 0 1.371-1.372 1.372 1.372 0 0 0-1.36 1.372h-.011Z"
    />
  </svg>
)
export const IconDotsCicle = memo(SvgComponent)
