import { api } from 'app/utils/api'
import { useCommentsContext } from '../context/CommentsContext'

interface UseCommentsQuery {
  parentId: string | null
}

export const useComments = ({ parentId = null }: UseCommentsQuery) => {
  const { topic, enabled } = useCommentsContext()
  return api.comment.list.useInfiniteQuery(
    { topic, parentId, limit: 20 },
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.commentsCollection?.pageInfo.hasNextPage &&
        lastPage.commentsCollection?.pageInfo.endCursor,
    }
  )
}
