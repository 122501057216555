import { Circle, SizableText, XStack, YStack, styled } from '@my/ui'
import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  XIcon,
} from 'react-share'

export const SocialShare = ({
  url,
  title,
  size = 24,
  onShareWindowClose,
}: {
  url: string
  title: string
  size?: number
  onShareWindowClose?: () => void
}) => {
  return (
    <XStack gap="$3" fw="wrap">
      <ChannelBox>
        <IconWrapper>
          <TwitterShareButton
            url={url}
            title={title}
            via="mydreamboyPr"
            hashtags={['NSFW', 'NSFWAI', 'Man', 'Porn']}
            onShareWindowClose={() => onShareWindowClose?.()}
          >
            <XIcon round size={size} />
          </TwitterShareButton>
        </IconWrapper>
        <ChannelText>Twitter</ChannelText>
      </ChannelBox>
      <ChannelBox>
        <IconWrapper>
          <FacebookShareButton
            url={url}
            title={title}
            onShareWindowClose={() => onShareWindowClose?.()}
          >
            <FacebookIcon round size={size} />
          </FacebookShareButton>
        </IconWrapper>
        <ChannelText>Facebook</ChannelText>
      </ChannelBox>
      <ChannelBox>
        <IconWrapper>
          <RedditShareButton
            url={url}
            windowWidth={660}
            windowHeight={460}
            title={title}
            onShareWindowClose={() => onShareWindowClose?.()}
          >
            <RedditIcon size={size} round />
          </RedditShareButton>
        </IconWrapper>
        <ChannelText>Reddit</ChannelText>
      </ChannelBox>
      <ChannelBox>
        <IconWrapper>
          <TelegramShareButton
            url={url}
            title={title}
            onShareWindowClose={() => onShareWindowClose?.()}
          >
            <TelegramIcon size={size} round />
          </TelegramShareButton>
        </IconWrapper>
        <ChannelText>Telegram</ChannelText>
      </ChannelBox>
    </XStack>
  )
}

const IconWrapper = styled(Circle, {
  cur: 'pointer',
  p: '$2',
  bg: '$gray4',
  hoverStyle: { bg: '$gray7' },
})

const ChannelText = styled(SizableText, {
  theme: 'alt1',
  size: '$1',
})

const ChannelBox = styled(YStack, {
  gap: '$2',
  ai: 'center',
})
