import { observer } from '@legendapp/state/react'
import { i18nInstance } from 'app/utils/i18n'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'solito/router'

import styles from './ModelCreateButton.module.css'
import enMessages from '../../locales/en/modelCreate.json'
import zhMessages from '../../locales/zh/modelCreate.json'
i18nInstance.addResourceBundle('en', 'modelCreateButton', enMessages)
i18nInstance.addResourceBundle('zh', 'modelCreateButton', zhMessages)

export const ModelCreateButton = observer(() => {
  const [t] = useTranslation('modelCreateButton')
  const router = useRouter()
  return (
    <button type="button" className={styles.btn} onClick={() => router.push('/model/generator')}>
      <strong className={styles.strong}>{t('create')}</strong>
      <div className={styles['container-stars']}>
        <div className={styles.stars} />
      </div>

      <div className={styles.glow}>
        <div className={styles.circle} />
        <div className={styles.circle} />
      </div>
    </button>
  )
})
