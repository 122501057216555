import { ActionIcon } from 'app/components/ActionIcon'
import { IconArrowRight } from 'app/icons/IconArrowRight'
import { i18nInstance } from 'app/utils/i18n'
import { useTranslation } from 'react-i18next'
import { Link } from 'solito/link'

import { UserAvatar } from '../UserAvatar/UserAvatar'
import enMessages from '../locales/en/userCreatedBadge.json'
import zhMessages from '../locales/zh/userCreatedBadge.json'
i18nInstance.addResourceBundle('en', 'userCreatedBadge', enMessages)
i18nInstance.addResourceBundle('zh', 'userCreatedBadge', zhMessages)

export const UserCreatedBadge = ({
  id,
  avatar,
  name,
}: {
  id: string
  avatar?: string
  name?: string
}) => {
  const [t] = useTranslation('userCreatedBadge')

  return (
    <Link href={`/user/${id}`}>
      <div className="bg-coal-100/60 hover:text-primary w-100% flex items-center justify-between gap-2 rounded-lg px-3 py-2 text-gray-600">
        <div className="flex flex-1 items-center gap-2">
          <UserAvatar avatarUrl={avatar} circular size="$3" />
          <div className="flex flex-1 flex-col">
            <p className="text-sm text-gray-600">{t('creator')}:</p>
            <p className="max-w-20 truncate text-sm text-gray-900 md:max-w-52">{name ?? 'Guest'}</p>
          </div>
        </div>
        <ActionIcon icon={<IconArrowRight className="h-3 w-3" />} />
      </div>
    </Link>
  )
}
