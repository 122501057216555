import { Observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { Dialog, MgButton, MgDialog, SizableText, XStack, YStack } from '@my/ui'
import { AlertCircle } from '@tamagui/lucide-icons'
import { deletedPostIds$ } from 'app/features/posts/stores/operatedPosts'
import { api } from 'app/utils/api'
import { useTranslation } from 'react-i18next'

import { hideModal, store$ } from '../../../context/PostDetailModelContext'

export const DeletePostDialog = observer(({ open$ }: { open$: Observable<boolean> }) => {
  const [t] = useTranslation('postDetail', { keyPrefix: 'deleteDialog' })
  const deleteMutation = api.post.delete.useMutation()

  const onDelete = async () => {
    const postId = Number(store$.post.id.peek())
    deleteMutation.mutateAsync({ id: postId }).catch(() => deletedPostIds$.delete(postId))
    deletedPostIds$.add(postId)
    open$.set(false)
    hideModal()
  }

  return (
    <MgDialog open={open$.get()} onOpenChange={(v) => open$.set(v)}>
      <MgDialog.Portal overlayProps={{ o: 0.4 }}>
        <MgDialog.Content maxWidth={360} bw="$1" boc="$gray2">
          <YStack ai="center" gap="$2">
            <AlertCircle strokeWidth={2.5} color="$red9" size="$3" />
            <Dialog.Title size="$7" textTransform="capitalize">
              {t('title')}
            </Dialog.Title>
            <SizableText ta="center">{t('desc')}</SizableText>
            <XStack mt="$3" gap="$3">
              <MgButton.Warning onPress={onDelete}>
                <MgButton.Text>{t('confirm')}</MgButton.Text>
              </MgButton.Warning>
              <MgButton.Default onPress={() => open$.set(false)}>
                <MgButton.Text>{t('cancel')}</MgButton.Text>
              </MgButton.Default>
            </XStack>
          </YStack>
        </MgDialog.Content>
      </MgDialog.Portal>
    </MgDialog>
  )
})
