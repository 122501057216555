import { observer } from '@legendapp/state/react'
import { MgFlatListIndicator, XStack, useMedia } from '@my/ui'
import { TagComboLabel } from 'app/features/tags/TagComboLabel/TagComboLabel'
import { TagComboV1Label } from 'app/features/tags/TagComboV1/TagComboV1Label'
import { api } from 'app/utils/api'
import { isStandardModelVersion } from 'app/utils/model-version'

import { store$ } from '../context/PostDetailModelContext'

export const PostDetailTag = observer(() => {
  const model = store$.model.get()

  return (
    <>
      {model.version === 'v1' ? <PostDetailV1Tag /> : <PostDetailV0Tag />}
      <PostDetailUserTag />
    </>
  )
})

const PostDetailV0Tag = observer(() => {
  const post = store$.post.get()

  return (
    <XStack flexWrap="wrap" gap="$2">
      {post.tag_combos?.map((combo, index) => (
        <XStack key={index}>
          {isStandardModelVersion(post.version!) ? (
            <TagComboLabel combo={combo} />
          ) : (
            <TagComboV1Label combo={combo} />
          )}
        </XStack>
      ))}
    </XStack>
  )
})

const PostDetailV1Tag = observer(() => {
  const post = store$.post.get()

  if (!post.tag_combos || !post.tag_combos.length) return null

  return (
    <div>
      <p className="mb-2 text-sm text-gray-500">Tags: </p>
      <div className="flex flex-wrap gap-2">
        {post.tag_combos.map((combo, index) => (
          <TagComboV1Label combo={combo} isLink key={index} />
        ))}
      </div>
    </div>
  )
})

const PostDetailUserTag = observer(() => {
  const post = store$.post.get()
  const { sm } = useMedia()

  const tagDetailQuery = api.tag.tagDetailByIds.useQuery(
    { ids: post.user_tags ?? [] },
    { enabled: !!post.user_tags?.length }
  )

  if (!tagDetailQuery.data) return null

  return (
    <div className="mt-2">
      <p className="mb-2 text-sm text-gray-500">User Tags: </p>
      <XStack gap="$2" w="100%">
        <MgFlatListIndicator.Horizontal
          showNavigator={!sm}
          showIndicator
          index={0}
          centerContent={false}
          data={tagDetailQuery.data}
          renderItem={({ item, index }) => {
            return (
              <img
                src={`${process.env.NEXT_PUBLIC_USER_LIBRARY_PREFIX}${item.coverImg}`}
                className="mx-2 w-11"
              />
            )
          }}
        />
      </XStack>
    </div>
  )
})
