import { CommentMetaData_Fragement } from '@my/api/src/routers/comment/graphql/index'
import { getFragmentData } from '@my/supabase/graphql/gql'
import { Paragraph, Spinner, XStack, YStack } from '@my/ui'
import { AlertCircle, MessagesSquare } from '@tamagui/lucide-icons'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IComment } from '../../typings/interface'
import { useCommentsContext } from '../context/CommentsContext'
import { useComments } from '../hooks/useComments'
import { CommentData } from './Comment'

interface CommentsProps {
  parentId: string | null
}

export const CommentList = ({ parentId }: CommentsProps) => {
  const [t] = useTranslation('commentList')
  const { enabled } = useCommentsContext()
  const commentQuery = useComments({ parentId })

  const comments = useMemo(
    () =>
      commentQuery.data?.pages.reduce(
        (prev, curr) =>
          prev.concat(
            (curr.commentsCollection
              ? curr.commentsCollection.edges.map(({ node }) => node)!
              : []) as IComment[]
          ),
        [] as IComment[]
      ) ?? ([] as IComment[]),
    [commentQuery.data?.pages]
  )

  if (!enabled) {
    return (
      <YStack ai="center">
        <Paragraph theme="alt2">{t('login')}</Paragraph>
      </YStack>
    )
  }

  return (
    <YStack>
      {commentQuery.isLoading && (
        <XStack jc="center">
          <Spinner />
        </XStack>
      )}
      {commentQuery.isError && (
        <YStack jc="center" ai="center" gap="$3">
          <AlertCircle color="$gray9" />
          <Paragraph theme="alt1">Unable to load comments.</Paragraph>
        </YStack>
      )}
      {commentQuery.isSuccess && !comments.length && (
        <YStack jc="center" ai="center" gap="$3">
          <MessagesSquare color="$gray9" />
          <Paragraph theme="alt1">No comments yet.</Paragraph>
        </YStack>
      )}
      <YStack gap="$3.5" mt="$3.5">
        {!!comments.length &&
          comments?.map((comment) => (
            <CommentData
              comment={comment}
              key={getFragmentData(CommentMetaData_Fragement, comment).id}
            />
          ))}
      </YStack>
      {commentQuery.hasNextPage &&
        (commentQuery.isFetching ? (
          <XStack jc="center" mt="$3.5" py="$1.5">
            <Spinner />
          </XStack>
        ) : (
          <XStack jc="center" mt="$5">
            <Paragraph
              theme="alt1"
              fontSize={12}
              cursor="pointer"
              onPress={() => commentQuery.fetchNextPage()}
            >
              View More Comments
            </Paragraph>
          </XStack>
        ))}
      {!!comments.length && !commentQuery.hasNextPage && !parentId && (
        <XStack jc="center" mt="$5">
          <Paragraph theme="alt1" fontSize={12}>
            THE END
          </Paragraph>
        </XStack>
      )}
    </YStack>
  )
}
