import { observer, useComputed } from '@legendapp/state/react'
import { Avatar, MgImage, XStack } from '@my/ui'
import { AvatarProps } from '@my/ui/types'
import { User } from '@tamagui/lucide-icons'
import { useUser } from 'app/utils/useUser'

export const UserAvatar = observer(
  ({
    avatarUrl,
    useSelfInfo = false,
    ...props
  }: AvatarProps & { avatarUrl?: string; useSelfInfo?: boolean }) => {
    const { avatarUrl$ } = useUser()
    const finalizeAvatarUrl$ = useComputed(() => {
      const url = useSelfInfo ? avatarUrl$.get() : avatarUrl
      return url
        ? url?.startsWith('http')
          ? url
          : `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/${url}`
        : null
    })

    if (!finalizeAvatarUrl$.get()) {
      return (
        <Avatar circular {...props}>
          <XStack w="100%" h="100%" backgroundColor="$gray5" jc="center" ai="center">
            <User width="100%" height="60%" />
          </XStack>
        </Avatar>
      )
    }

    return (
      <Avatar circular {...props}>
        <MgImage
          source={{ uri: finalizeAvatarUrl$.get()! }}
          failChildren={
            <XStack w="100%" h="100%" backgroundColor="$gray5" jc="center" ai="center">
              <User width="100%" height="60%" />
            </XStack>
          }
          flex={1}
        />
      </Avatar>
    )
  }
)
