import { Paragraph } from '@my/ui'
import { i18nInstance } from 'app/utils/i18n'
import enMessages from '../locales/en/commentList.json'
import zhMessages from '../locales/zh/commentList.json'
import { CommentList } from './components/CommentList'
import { CommentsProvider } from './context/CommentsContext'

i18nInstance.addResourceBundle('en', 'commentList', enMessages)
i18nInstance.addResourceBundle('zh', 'commentList', zhMessages)

export const Comments = ({ topic }: { topic: string }) => {
  return (
    <CommentsProvider topic={topic}>
      <Paragraph theme="alt1" fontSize={14} my="$4">
        Comments
      </Paragraph>
      <CommentList parentId={null} />
    </CommentsProvider>
  )
}
