import { observer, useComputed, useObservable } from '@legendapp/state/react'
import { useMemoizedFn } from 'ahooks'
import { ActionIcon } from 'app/components/ActionIcon'
import { Button } from 'app/components/Button'
import { GeneratedImgWithOP } from 'app/features/generate/GeneratedImage/GeneratedImgWithOP'
import { imageEditorStore } from 'app/features/generate/stores/imageEditor'
import { useIsMobile } from 'app/hooks/useMediaQuery'
import { IconFileDown } from 'app/icons/IconFileDown'
import { api } from 'app/utils/api'
import { downloadImage } from 'app/utils/download'
import { useAlgImgUrlPrefix } from 'app/utils/hooks/useAlgImgUrlPrefix'
import { getBase64ByUrl } from 'app/utils/image'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'sonner'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import Lightbox, { SlideImage } from 'yet-another-react-lightbox'
import Counter from 'yet-another-react-lightbox/plugins/counter'
import Download from 'yet-another-react-lightbox/plugins/download'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'yet-another-react-lightbox/plugins/counter.css'
import 'yet-another-react-lightbox/styles.css'

import { useIsSelfPost } from '../../hooks/usePostStatus'
import { localPosts } from '../../stores/localPosts'
import { IPost } from '../../typings/post'
import { store$ } from '../context/PostDetailModelContext'
import '../css/post-detail-modal.css'
import { onDownload } from '../event'
import { GenerateMoreSlide } from './GenerateMore/GenerateMoreSlide'

export const PostModalSlide = observer(() => {
  const index$ = useObservable(0)
  const open$ = useObservable(false)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const swiper$ = useRef<SwiperClass | null>(null)

  const prefix = useAlgImgUrlPrefix()
  const isModelPrivate$ = useComputed(() => store$.model.private.get())

  const containerRef = useRef<HTMLDivElement>(null)
  const height$ = useObservable('auto')
  const isMobile = useIsMobile()
  const router = useRouter()
  const post$ = useComputed(() => {
    const post = store$.post.get()
    const localPost = post ? localPosts.getPost(post.id) : {}
    return {
      ...post,
      ...localPost,
    }
  })
  const aspectRatio$ = useComputed(() => {
    const coverImg = post$.images.get()?.[0]
    return coverImg ? (coverImg.height / coverImg.width) * 100 + 15 : 0
  })
  const isSelfPost = useIsSelfPost(post$.get())

  const updateCoverMutation = api.post.update.useMutation()

  const onEdit = useMemoizedFn(async (image: NonNullable<IPost['images']>[number]) => {
    if (!image) return

    const base64 = await getBase64ByUrl(`${prefix}${image.pid}`)
    if (base64) {
      imageEditorStore.selectImg({
        base64,
        remainFaceIds: image.inpaint_info?.remain_face_human_ids ?? null,
        sourceAssetPath: image.inpaint_info?.inpaint_source_foldpath ?? '',
      })
      router.push('/image-editor')
    }
  })

  const onUpdateCover = useMemoizedFn(async (image: NonNullable<IPost['images']>[number]) => {
    if (!image || !isSelfPost) return
    const images = post$.images.peek()
    const newImages = [image]
    newImages.push(...images.filter((v) => v.pid !== image.pid))
    updateCoverMutation.mutateAsync({
      id: Number(post$.id.peek()),
      images: newImages,
    })
    localPosts.setImages({
      postId: post$.id.peek(),
      images: newImages,
    })
    swiper$.current?.slideTo(0)
  })

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        })
      }
    }

    updateDimensions()
    window.addEventListener('resize', updateDimensions)

    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {
    return onDownload.on(() => {
      downloadImage(`${prefix}${post$.images.peek()[index$.peek()]?.pid}`)
    })
  }, [index$, post$.images, prefix])

  useEffect(() => {
    const aspectRatio = (aspectRatio$.get() - 15) / 100
    const imgHeight = (dimensions.width ?? 0) * aspectRatio
    if (isMobile) {
      height$.set(`${imgHeight}px`)
    } else {
      const imgHeight = dimensions.width * aspectRatio
      if (imgHeight > window.innerHeight * 0.83) {
        height$.set(`${window.innerHeight * 0.83 - 56}px`)
      } else {
        height$.set(`${imgHeight}px`)
      }
    }
  }, [aspectRatio$, dimensions, height$, isMobile])

  return (
    <>
      <div
        ref={containerRef}
        className="border-coal-100 post-modal-slide relative max-h-full w-full"
      >
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          onActiveIndexChange={(v) => index$.set(v.activeIndex)}
          onSwiper={(swiper) => (swiper$.current = swiper)}
        >
          {post$.get()?.images?.map((image, index) => (
            <SwiperSlide
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 12,
              }}
            >
              <div className="bg-coal-100/30 flex h-full w-full flex-col gap-1 overflow-hidden rounded-lg">
                <div
                  className="relative mx-auto w-full"
                  style={{
                    paddingTop: height$.get(),
                  }}
                >
                  <div
                    className="absolute inset-0 flex flex-col overflow-hidden"
                    onClick={() => {
                      open$.set(true)
                    }}
                  >
                    <GeneratedImgWithOP
                      image={image}
                      post={post$.get()}
                      model={store$.model.get()}
                    />
                  </div>
                </div>
                <div className="flex w-full items-center justify-between gap-1 px-2 py-1 md:px-5">
                  <div className="flex items-center gap-2">
                    <Button
                      showArrow={false}
                      mode="transparent"
                      className="flex items-center justify-center rounded-lg px-2 py-1 text-sm text-gray-600 hover:text-gray-900 active:text-gray-900 md:text-base"
                      onClick={() => {
                        toast.promise(onEdit(image), {
                          loading: 'Fetching image...',
                          error: 'Failed to fetch image',
                        })
                      }}
                    >
                      Edit
                    </Button>
                    {index > 0 && isSelfPost && (
                      <Button
                        mode="transparent"
                        showArrow={false}
                        className="flex items-center justify-center whitespace-nowrap rounded-lg px-2 py-1 text-sm text-gray-600 hover:text-gray-900 active:text-gray-900 md:text-base"
                        onClick={() => onUpdateCover(image)}
                        loading={updateCoverMutation.isLoading}
                      >
                        Set Cover
                      </Button>
                    )}
                  </div>
                  <ActionIcon
                    icon={
                      <IconFileDown className="hover:text-primary-active h-6 w-6 text-gray-600" />
                    }
                    square
                    onClick={() => {
                      downloadImage(`${prefix}${image.pid}`)
                    }}
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
          {!isModelPrivate$.get() && (
            <SwiperSlide
              style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}
            >
              <div className="relative w-full" style={{ paddingTop: `${height$.get()}` }}>
                <div className="absolute inset-0 flex flex-col">
                  <GenerateMoreSlide />
                </div>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </div>
      <Lightbox
        styles={{ root: { pointerEvents: 'auto' } }}
        open={open$.get()}
        close={() => open$.set(false)}
        index={index$.get()}
        plugins={[Counter, Zoom, Download]}
        slides={
          [
            ...(post$.get()?.images?.map(({ width, height, pid }) => ({
              src: `${prefix}${pid}`,
              width,
              height,
            })) ?? []),
            isModelPrivate$.get() ? null : { src: '', width: 904, height: 904 },
          ].filter((v) => !!v) as SlideImage[]
        }
        render={{
          slide: ({ slide }) => {
            if (!slide.src) return <GenerateMoreSlide />
            return undefined
          },
        }}
        on={{ view: ({ index }) => index$.set(index) }}
        animation={{ fade: 0 }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
      />
    </>
  )
})
