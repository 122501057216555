import { AlertDialog, MgButton, Paragraph } from '@my/ui'
import { X } from '@tamagui/lucide-icons'
import { useScroll } from 'ahooks'
import { useUser } from 'app/utils/useUser'
import useViewportState from 'beautiful-react-hooks/useViewportState'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'solito/link'

export const SignupMasker = () => {
  const [t] = useTranslation('home', { keyPrefix: 'signupMasker' })
  const [open, setOpen] = useState(false)
  const [opened, setOpened] = useState(false)
  const { isLogin, isLoadingSession } = useUser()
  const scrollPosition = useScroll(document.getElementById('J_ScrollContainer') ?? document.body)
  const { height } = useViewportState()

  useEffect(() => {
    if (!isLogin && scrollPosition && scrollPosition.top > height && !opened) {
      setOpen(true)
      setOpened(true)
    }
  }, [height, isLogin, opened, scrollPosition, scrollPosition?.top])

  if (isLogin || isLoadingSession) return null

  return (
    <AlertDialog open={open} onOpenChange={(v) => setOpen(v)}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay
          key="MgAlertDialogOverlay"
          animation="quick"
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
          opacity={0.6}
          onPress={() => setOpen(false)}
        />
        <AlertDialog.Content gap="$4" py="$6">
          <AlertDialog.Title ta="center" col="$blue9">
            {t('title')}
          </AlertDialog.Title>
          <AlertDialog.Description ta="center" fow="bold" col="$color12">
            {t('desc')}
          </AlertDialog.Description>
          <AlertDialog.Cancel jc="center" ai="center">
            <Link href="/sign-up">
              <MgButton.Active>
                <MgButton.Text>{t('btnText')}</MgButton.Text>
              </MgButton.Active>
            </Link>
          </AlertDialog.Cancel>
          <Paragraph ta="center" theme="alt1">
            {t('hint')}
          </Paragraph>
          <MgButton.Default
            pos="absolute"
            right={12}
            top={12}
            size="$2"
            circular
            icon={<X strokeWidth={3} />}
            onPress={() => setOpen(false)}
          />
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog>
  )
}
