import { themed } from '@tamagui/helpers-icon'
import { Skeleton } from 'moti/skeleton'
import { ReactNode, memo, useState, useEffect } from 'react'
import { Path, Svg } from 'react-native-svg'
import { ColorTokens, Image, ImageProps, XStack } from 'tamagui'

export type MgImageProps = ImageProps & { failChildren?: ReactNode }

type ImageStatus = 'loading' | 'loaded' | 'error'

export const MgImage: React.FC<MgImageProps> = ({ failChildren, ...props }) => {
  const [status, setStatus] = useState<ImageStatus>('loading')

  useEffect(() => {
    if (props.source instanceof Object) {
      if (status === 'error') {
        setStatus('loading')
      }
    }
  }, [props.source])

  if (status === 'error') {
    return (
      <XStack flex={1} w="100%" jc="center" ai="center">
        {failChildren ?? <IconImgFailed color="$gray6" size="$5" />}
      </XStack>
    )
  }
  return (
    <Skeleton width="100%" height="100%" radius={0} colorMode="dark" show={status === 'loading'}>
      <Image
        {...props}
        onLoad={() => setStatus('loaded')}
        onError={(e) => {
          setStatus('error')
          setTimeout(() => {
            props.onError?.(e)
          })
        }}
      />
    </Skeleton>
  )
}

const IconImgFailed = memo(
  themed((props: any) => {
    const { size, color, ...rest } = props
    return (
      <Svg
        color={color as ColorTokens | undefined}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        {...rest}
      >
        <Path
          fill="currentColor"
          d="M30 3.414 28.586 2 2 28.586 3.414 30l2-2H26a2.003 2.003 0 0 0 2-2V5.414zM26 26H7.414l7.793-7.793 2.379 2.379a2 2 0 0 0 2.828 0L22 19l4 3.997zm0-5.832-2.586-2.586a2 2 0 0 0-2.828 0L19 19.168l-2.377-2.377L26 7.414zM6 22v-3l5-4.997 1.373 1.374 1.416-1.416-1.375-1.375a2 2 0 0 0-2.828 0L6 16.172V6h16V4H6a2.002 2.002 0 0 0-2 2v16z"
        />
      </Svg>
    )
  })
)
