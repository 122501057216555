import { useMutation } from '@tanstack/react-query'
import { api } from 'app/utils/api'
import { editor$, resetEditStore } from '../store/editor'

export const useAddComment = () => {
  const utils = api.useContext()
  return useMutation(
    () => {
      const { topic, value, parentId } = editor$.peek()
      return utils.comment.add.fetch({
        topic,
        comment: value,
        parentId,
        timestamp: new Date().getTime(),
      })
    },
    {
      onSuccess: () => {
        const { topic } = editor$.peek()
        resetEditStore({ topic: editor$.topic.peek() })
        utils.comment.list.invalidate({ topic })
      },
    }
  )
}
