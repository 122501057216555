import { ActionIcon } from 'app/components/ActionIcon'
import { Button } from 'app/components/Button'
import Modal from 'app/components/Modal'
import { copyToClipboard } from 'app/utils/hooks/useClipboard'
import { X } from 'lucide-react'
import { toast } from 'sonner'

import { SocialShare } from '../SocialShare/SocialShare'

export const ShareModal = ({
  url,
  title,
  afterShare,
  children,
  open,
  onClose,
}: {
  url: string
  title: string
  afterShare: () => void
  children?: React.ReactNode
  open: boolean
  onClose: () => void
}) => {
  const onCopy = () => {
    copyToClipboard(url)
    toast.success('Copied to clipboard')
  }
  return (
    <Modal isOpen={open} onClose={onClose} showCloseButton={false} mountToBody={false}>
      <div className="flex w-[90vw] flex-col md:w-[400px]">
        <div className="border-coal-200 flex w-full items-center justify-between border-b p-3">
          <p className="text-xl font-bold">Share</p>
          <ActionIcon rounded icon={<X className="h-4 w-4" />} onClick={() => onClose()} />
        </div>
        <div className="p-3">
          <SocialShare url={url} title={title} size={32} onShareWindowClose={() => afterShare()} />
        </div>
        <div className="flex items-center justify-between gap-4 p-3">
          <div className="bg-coal-100 flex-1 truncate rounded-md p-2 text-sm text-gray-600">
            {url}
          </div>
          <Button
            className="w-fit px-4 py-2 text-sm"
            mode="transparent"
            showArrow={false}
            onClick={onCopy}
          >
            Copy Link
          </Button>
        </div>
        {children}
      </div>
    </Modal>
  )
}
