import { observer } from '@legendapp/state/react'
import { Avatar, MgImage } from '@my/ui'
import { AvatarProps } from '@my/ui/types'

import { localModelAvatar$ } from './store'

type Props = AvatarProps & {
  pid: string
  modelId: string
}

export const ModelAvatar = observer(({ pid, modelId, ...props }: Props) => {
  const normalizePid = localModelAvatar$.get()[modelId] ?? pid
  return (
    <Avatar circular {...props}>
      <MgImage
        source={{ uri: `${process.env.NEXT_PUBLIC_ALG_IMAGE_PREFIX}${normalizePid}` }}
        flex={1}
        width="100%"
      />
    </Avatar>
  )
})
