import { useUser } from 'app/utils/useUser'
import { useRouter } from 'solito/router'

export const useCheckLogin = () => {
  const { isLogin } = useUser()
  const router = useRouter()

  return {
    checkLogin: () => {
      if (isLogin) return true
      else {
        router.push('/sign-in')
        return false
      }
    },
  }
}
