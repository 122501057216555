import { CommentMetaData_Fragement } from '@my/api/src/routers/comment/graphql/index'
import { getFragmentData } from '@my/supabase/graphql/gql'
import { MgLikeButton, Paragraph, XStack, YStack } from '@my/ui'
import { MessageCircle } from '@tamagui/lucide-icons'
import { UserAvatar } from 'app/features/users/UserAvatar/UserAvatar'
import { useMemo, useState } from 'react'
import { Link } from 'solito/link'

import { CommentList } from './CommentList'
import { TimeAgo } from './TimeAgo'
import { editor$ } from '../../Editor/store/editor'
import { IComment } from '../../typings/interface'
import { useToggleCommentLike } from '../hooks/useToggleCommentLike'

interface Props {
  comment: IComment
}

export const CommentData = ({ comment }: Props) => {
  const CommentMetaData = useMemo(
    () => getFragmentData(CommentMetaData_Fragement, comment),
    [comment]
  )
  const { like, likeCount, toggleLike } = useToggleCommentLike(CommentMetaData)
  const [repliesVisible, setRepliesVisible] = useState(false)
  const isReply = !!CommentMetaData.parent_id
  const hasPeply = CommentMetaData && (CommentMetaData.replies ?? 0) > 0
  return (
    <XStack gap="$3">
      <Link href={`/user/${CommentMetaData.user_id}`}>
        <UserAvatar avatarUrl={CommentMetaData.user.avatar_url ?? ''} size={28} />
      </Link>
      <YStack
        flex={1}
        gap="$2"
        pb="$3.5"
        borderBottomWidth={isReply ? '$0' : '$0.5'}
        borderColor="$mogg.grayBg"
      >
        <Paragraph theme="alt1" fontSize={14}>
          {CommentMetaData.user.name}
        </Paragraph>
        <Paragraph>{CommentMetaData.comment}</Paragraph>
        <XStack jc="space-between" ai="center" mt="$2.5">
          <Paragraph theme="alt1" fontSize={12}>
            <TimeAgo date={CommentMetaData.created_at} locale="en-US" />
          </Paragraph>
          <XStack gap="$4.5" ai="center">
            <XStack ai="center" gap="$1.5">
              <XStack onPress={() => toggleLike()}>
                <MgLikeButton size={12} checked={like} />
              </XStack>
              {likeCount > 0 && (
                <Paragraph theme="alt1" fontSize={12}>
                  {likeCount}
                </Paragraph>
              )}
            </XStack>
            {!CommentMetaData.parent_id && (
              <XStack ai="center" gap="$1.5">
                <XStack
                  onPress={() => {
                    editor$.parentId.set(CommentMetaData.id)
                    editor$.replyingTo.set(comment)
                    editor$.focus.set(true)
                  }}
                >
                  <MessageCircle color="$gray9" size={12} />
                </XStack>
                {CommentMetaData && (CommentMetaData.replies ?? 0) > 0 && (
                  <Paragraph theme="alt1" fontSize={12}>
                    {CommentMetaData.replies}
                  </Paragraph>
                )}
              </XStack>
            )}
          </XStack>
        </XStack>
        {!repliesVisible && hasPeply && (
          <XStack>
            <Paragraph
              theme="alt1"
              fontSize={12}
              cursor="pointer"
              onPress={() => setRepliesVisible(true)}
            >
              View Replies
            </Paragraph>
          </XStack>
        )}
        {repliesVisible && !isReply && (
          <YStack>
            <CommentList parentId={CommentMetaData.id} />
          </YStack>
        )}
      </YStack>
    </XStack>
  )
}
