import { observer } from '@legendapp/state/react'
import { ActionIcon } from 'app/components/ActionIcon'
import { useIsMobile } from 'app/hooks/useMediaQuery'
import { IconCrossCircle } from 'app/icons/IconCrossCircle'

import { useIsSelfPost } from '../../hooks/usePostStatus'
import { hideModal, store$ } from '../context/PostDetailModelContext'
import { PostDetailMenu } from './PostDetailMenu/PostDetailMenu'
import { PostModelInfo } from './PostModelInfo'

export const PostLeftBar = observer(() => {
  const isMobile = useIsMobile()
  const isSelfPost = useIsSelfPost(store$.post.get())

  if (isMobile) {
    return (
      <div className="flex w-full items-center justify-between border-b border-solid border-gray-100 px-4 py-2">
        <PostModelInfo />
        <div className="flex items-center justify-center">
          {isSelfPost && <PostDetailMenu />}
          <CloseButton />
        </div>
      </div>
    )
  }
  return (
    <div className="absolute right-6 top-6">
      <CloseButton />
    </div>
  )
})

const CloseButton = () => {
  return (
    <ActionIcon
      icon={
        <IconCrossCircle className="hover:text-primary-active h-6 w-6 text-gray-600 md:h-8 md:w-8" />
      }
      square
      onClick={hideModal}
    />
  )
}
