import { Button, ButtonProps, Spinner, TextProps, YStack, withStaticProperties } from 'tamagui'

export type MgButtonProps = ButtonProps & { loading?: boolean }

const Wrapper: React.FC<MgButtonProps> = (props) => {
  return <YStack>{/* */}</YStack>
}

const DefaultWrapper = ({ loading = false, ...props }: MgButtonProps) => {
  return (
    <Button
      br="$10"
      opacity={props.disabled ? 0.6 : 1}
      iconAfter={loading ? <Spinner /> : null}
      {...props}
    >
      {props.children}
    </Button>
  )
}

const InverseWrapper = (props: MgButtonProps) => {
  return <DefaultWrapper themeInverse {...props} />
}

const ActiveWrapper = (props: MgButtonProps) => {
  return (
    <DefaultWrapper
      backgroundColor="$blue9"
      br="$10"
      theme="blue"
      hoverStyle={{ bc: '$blue10' }}
      pressStyle={{ bc: '$blue9', b: 'none' }}
      opacity={props.disabled ? 0.6 : 1}
      {...props}
    />
  )
}

const WarningWrapper = (props: MgButtonProps) => {
  return (
    <DefaultWrapper
      bc="$red9"
      br="$10"
      theme="red"
      hoverStyle={{ bc: '$red10' }}
      pressStyle={{ bc: '$red9', b: 'none' }}
      opacity={props.disabled ? 0.6 : 1}
      {...props}
    />
  )
}

const TextWrapper = (props: TextProps) => {
  return (
    <Button.Text fow="700" {...props}>
      {props.children}
    </Button.Text>
  )
}

export const MgButton = withStaticProperties(Wrapper, {
  Active: ActiveWrapper,
  Inverse: InverseWrapper,
  Default: DefaultWrapper,
  Warning: WarningWrapper,
  Text: TextWrapper,
})
