import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M12 15.76a3.767 3.767 0 1 1 3.767-3.767A3.778 3.778 0 0 1 12 15.761Zm0-5.965a2.198 2.198 0 1 0 0 4.396 2.198 2.198 0 0 0 0-4.396Zm0 11.941a11.95 11.95 0 0 1-7.608-3.202C2.11 16.534.75 14.086.75 11.993.75 7.347 6.642 2.25 12 2.25s11.25 5.233 11.25 9.743c0 4.73-5.787 9.743-11.25 9.743ZM12 3.82c-4.427 0-9.68 4.427-9.68 8.173 0 1.633 1.193 3.684 3.14 5.358A10.518 10.518 0 0 0 12 20.166c4.981 0 9.68-4.604 9.68-8.173 0-3.38-5.002-8.173-9.68-8.173Z"
    />
  </svg>
)
export const IconEye = memo(SvgComponent)
