import { TamaguiElement } from '@my/ui'
import { useEffect, useState } from 'react'
import { useScrollDirection } from 'react-use-scroll-direction'
import { useObservable } from '../legendState'

export const useIsScrollUp = (initial?: boolean) => {
  const isScrollUp$ = useObservable(initial ?? false)
  const [scrollContainer, setScrollContainer] = useState<TamaguiElement | null>(null)
  const { isScrollingUp, isScrollingDown } = useScrollDirection(scrollContainer as HTMLElement)

  useEffect(() => {
    if (isScrollingUp) {
      isScrollUp$.set(true)
    }
    if (isScrollingDown) {
      isScrollUp$.set(false)
    }
  }, [isScrollUp$, isScrollingDown, isScrollingUp])
  return {
    isScrollUp$,
    setScrollContainer,
  }
}
