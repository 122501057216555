import { useComputed } from '@legendapp/state/react'
import { Enums } from '@my/supabase/helpers'

import { useUser } from '../useUser'

export const useAlgImgUrlPrefix = () => {
  const { userData$ } = useUser()
  const noWatermark = useComputed(() =>
    (['premium', 'pro'] as Enums<'subscription_tier'>[]).includes(userData$.subscription_tier.get())
  )

  return noWatermark.get()
    ? process.env.NEXT_PUBLIC_ALG_IMAGE_PREFIX
    : process.env.NEXT_PUBLIC_ALG_WATERMARK_IMAGE_PREFIX
}

export const useFallbackImgUrlPrefix = () => {
  const prefix = useAlgImgUrlPrefix()
  return prefix === process.env.NEXT_PUBLIC_ALG_IMAGE_PREFIX
    ? process.env.NEXT_PUBLIC_ALG_WATERMARK_IMAGE_PREFIX
    : process.env.NEXT_PUBLIC_ALG_IMAGE_PREFIX
}
