import { observable, observe } from "@legendapp/state";
import { CommentMetaData_Fragement } from "@my/api/src/routers/comment/graphql/index";
import { getFragmentData } from "@my/supabase/graphql/gql";
import { IComment } from "../../typings/interface";

const defaultValue = {
  topic: '',
  parentId: undefined as string | undefined,
  replyingTo: null as IComment | null,
  value: '',
  placeholder: 'Write a message...',
  readOnly: false,
  focus: false,
}

export const editor$ = observable<typeof defaultValue>(defaultValue)

export const resetEditStore = (value: Partial<typeof defaultValue> = {}) => editor$.set({ ...defaultValue, ...value })

observe(() => {
  if (editor$.replyingTo.get()) {
    editor$.placeholder.set(`Reply to ${getFragmentData(CommentMetaData_Fragement, editor$.replyingTo.get()).user.name}`)
  } else {
    editor$.placeholder.set('Write a message...')
  }
})



