import { observer, useObservable } from '@legendapp/state/react'
import { MgImage } from '@my/ui'
import { ImageProps } from '@my/ui/types'
import { useAlgImgUrlPrefix, useFallbackImgUrlPrefix } from 'app/utils/hooks/useAlgImgUrlPrefix'
import { useEffect } from 'react'

type Props = Omit<ImageProps, 'source'> & {
  item: { pid: string; width: number; height: number }
  noWatermark?: boolean
}

export const GeneratedImg = observer(({ item, noWatermark = false, ...props }: Props) => {
  const prefix = useAlgImgUrlPrefix()
  const fallbackPrefix = useFallbackImgUrlPrefix()
  const { pid } = item

  const prefix$ = useObservable(prefix)

  useEffect(() => {
    prefix$.set(noWatermark ? process.env.NEXT_PUBLIC_ALG_IMAGE_PREFIX : prefix)
  }, [noWatermark, prefix, prefix$])

  return (
    <MgImage
      source={{ uri: `${prefix$.get()}${pid}` }}
      width="100%"
      height="100%"
      resizeMode="contain"
      onError={() => prefix$.set(fallbackPrefix)}
      {...props}
    />
  )
})
