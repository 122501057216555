export const isPastByCompareStr = ({ date, origin }: { date: string; origin: string }) => {
  const [year, month, day] = date.split('-').map((v) => Number(v)) as [number, number, number]
  const [oldYear, oldMonth, oldDay] = origin.split('-').map((v) => Number(v)) as [
    number,
    number,
    number
  ]

  return (
    year < oldYear ||
    (year === oldYear && month < oldMonth) ||
    (year === oldYear && month === oldMonth && day < oldDay)
  )
}

export const isSameDayByCompareStr = ({ date, origin }: { date: string; origin: string }) => {
  const [year, month, day] = date.split('-').map((v) => Number(v)) as [number, number, number]
  const [oldYear, oldMonth, oldDay] = origin.split('-').map((v) => Number(v)) as [
    number,
    number,
    number
  ]

  return year === oldYear && month === oldMonth && day === oldDay
}
