import { observable } from '@legendapp/state'

import { IPost } from '../typings/post'

export const localPosts = {
  state$: observable({
    posts: {} as Record<string, IPost>,
  }),

  getPost(postId: string) {
    const posts$ = localPosts.state$.posts
    const post = posts$[postId]?.peek()
    if (!post) {
      posts$[postId].set({ id: postId })
    }
    return posts$[postId].get()
  },

  setImages({ postId, images }: { postId: string; images: IPost['images'] }) {
    const posts$ = localPosts.state$.posts
    if (posts$[postId].peek() && posts$[postId].peek().images) {
      posts$[postId].images.set(images ?? [])
    } else {
      posts$[postId].set({
        ...posts$[postId].peek(),
        id: postId,
        images,
      })
    }
  },
}
