import { ButtonProps, MgButton } from '@my/ui'
import { i18nInstance } from 'app/utils/i18n'
import { useRouter as useNextRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import enMessages from '../locales/en/signIn.json'
import zhMessages from '../locales/zh/signIn.json'
i18nInstance.addResourceBundle('en', 'signIn', enMessages)
i18nInstance.addResourceBundle('zh', 'signIn', zhMessages)

export const SignInButton = (props: ButtonProps) => {
  const [t] = useTranslation('signIn')
  const router = useNextRouter()

  return (
    <MgButton.Active onPress={() => router.push('/sign-in')} {...props}>
      <MgButton.Text>{t('login')}</MgButton.Text>
    </MgButton.Active>
  )
}
