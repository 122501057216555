import { graphql } from "@my/supabase/graphql/gql"

export const CommentMetaData_Fragement = graphql(`
fragment commentMetaData on comments {
  id
  topic
  comment
  user_id
  parent_id
  created_at
  likes
  replies
  comment_likesCollection(filter: {user_id: {eq: $userId}}) {
    edges {
      node {
        id
        comment_id
        user_id
      }
    }
  }
  user {
    avatar_url
    name
  }
}
`)

export const queryCommentList = graphql(`
query commentList($topic: String, $userId: UUID, $limit: Int, $cursor: Cursor) {
  commentsCollection(
    filter: {topic: {eq: $topic}, parent_id: {is: NULL}}
    first: $limit
    after: $cursor
    orderBy: {created_at: AscNullsLast}
  ) {
    edges {
      node {
        ...commentMetaData
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
`)

export const queryReplyList = graphql(`
query replyList($topic: String, $parentId: UUID, $userId: UUID, $limit: Int, $cursor: Cursor) {
  commentsCollection(
    filter: {topic: {eq: $topic}, parent_id: {eq: $parentId}}
    first: $limit
    after: $cursor
    orderBy: {created_at: AscNullsLast}
  ) {
    edges {
      node {
        ...commentMetaData
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
`)
