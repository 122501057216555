import { observer } from '@legendapp/state/react'
import { IconGenerate } from 'app/icons/IconGenerate'
import { useTranslation } from 'react-i18next'

import { useGenerateMore } from './hooks/useGenerateMore'

export const GenerateMoreButton = observer(() => {
  const [t] = useTranslation('postDetail', { keyPrefix: 'generateMore' })
  const { onGenerateMore } = useGenerateMore()

  return (
    <button
      className="bg-coal-100/60 hover:text-primary flex flex-1 items-center gap-2 rounded-lg px-3 py-2 text-gray-600"
      onClick={onGenerateMore}
    >
      <IconGenerate className="h-6 w-6" />
      <p className="text-sm">{t('btnText')}</p>
    </button>
  )
})
