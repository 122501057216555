import { mergeIntoObservable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { MgButton, ToastChildren } from '@my/ui'
import { localModelAvatar$ } from 'app/features/models/ModelAvatar/store'
import { api } from 'app/utils/api'
import { showToast } from 'app/utils/toast'
import { useUser } from 'app/utils/useUser'
import { useTranslation } from 'react-i18next'
import { useGeneratedImgWithOP } from '../context/GeneratedImgWithOPContext'

export const AsAvatarButton = observer(() => {
  const [t] = useTranslation('generateImage')
  const { user } = useUser()
  const { post, image, model, showAvatarBtn } = useGeneratedImgWithOP()
  const updateMutation = api.model.update.useMutation()
  const isPortraint = post.tag_combos?.some((combo) => combo.includes(212)) // portraint code
  const canModify =
    model.avatar !== image.pid &&
    localModelAvatar$.get()[model.id!] !== image.pid &&
    model.creator === user?.id

  const onSave = async () => {
    try {
      mergeIntoObservable(localModelAvatar$, { [model.id!]: image.pid })
      await updateMutation.mutateAsync({ id: model.id!, avatar: image.pid })
      showToast(<ToastChildren.Success text={t('asAvatarSuccess')} />)
    } catch (e) {
      showToast(<ToastChildren.Success text={t('asAvatarFailed')} />)
      localModelAvatar$[model.id!]?.delete()
    }
  }

  if (!isPortraint || !canModify || !showAvatarBtn) {
    return null
  }

  return (
    <MgButton.Inverse size="$2" onPress={() => onSave()}>
      <MgButton.Text>{t('asAvatar')}</MgButton.Text>
    </MgButton.Inverse>
  )
})
